import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransferSelect } from '../../interfaces/transfer-select.interface';

@Component({
  selector: 'app-transfer-select',
  templateUrl: './transfer-select.component.html',
  styleUrl: './transfer-select.component.less',
})
export class TransferSelectComponent {
  options: { key: string; value: string }[] = [
    {
      key: '1',
      value: 'Option 1',
    },
    {
      key: '2',
      value: 'Option 2',
    },
    {
      key: '3',
      value: 'Option 3',
    },
    {
      key: '4',
      value: 'Option 4',
    },
    {
      key: '5',
      value: 'Option 5',
    },
  ];
  values: string[] = [];
  title: string = 'Sealect Items';
  availableTitle: string = 'Available Items';
  selectedTitle: string = 'Selected Items';
  searchKey: string = '';

  constructor(
    private dialogRef: MatDialogRef<TransferSelectComponent>,
    @Inject(MAT_DIALOG_DATA) payload: TransferSelect
  ) {
    this.options = [...payload.options];
    this.values = [...payload.values];
    this.title = payload.title;
    this.availableTitle = payload.availableTitle;
    this.selectedTitle = payload.selectedTitle;
  }

  get availableOptions(): { key: string; value: string }[] {
    return [
      ...this.options.filter((option) => !this.values.includes(option.key)),
    ];
  }
  get selectedOptions(): { key: string; value: string }[] {
    return [
      ...this.options.filter((option) => this.values.includes(option.key)),
    ];
  }

  toggleSelect(key: string) {
    const index = this.values.findIndex((value) => value == key);
    if (index >= 0) {
      this.values.splice(index, 1);
    } else {
      this.values.push(key);
    }
  }

  saveSelection() {
    this.dialogRef.close([...this.values]);
  }
  cancelSelection(){
    this.dialogRef.close();
  }
}
