export class GenericCMSModel {
    [LogIn:string]: string ;
    BecomePartner!: string;
    ForgetPassword!: string;
    Email!: string;
    Password!: string;
    EnterEmail!: string;
    Cancel!: string;
    Close!: string;
    Submit!: string;
    EnterNewPassword!: string;
    VerifyNewPassword!: string;
    Proceed!: string;
    Continue!: string;
    Search!: string;
    Next!: string;
    Previous!: string;
    Country!: string;
    City!: string;
    Address!: string;

    //onboardng labels

    Title!: string;
    FirstName!: string;
    LastName!: string;
    Phone!: string;
    ConfirmPassword!: string;
    CommunicationChannel!: string;
    EntityType!: string;
    BusinessName!: string;
    TradingName!: string;
    Industry!: string;
    RegistrationNumber!: string;
    NationalID!: string;
    passwordLengthHint!: string;
    passwordLowercaseHint!: string;
    passwordUppercaseHint!: string;
    passwordNumbercaseHint!: string;
    passwordSpecialHint!: string;
    PasswordNotMatch!: string;
    EnterValidEmail!: string;

    //offers Labels

    Offers!: string;
    OfferTitle!: string;
    OfferType!: string;
    StartDate!: string;
    ExpirationDate!: string;
    Status!: string;

    //profile

    ResetPassword!: string;
    Reset2FA!: string;
    JoiningDate!: string;
    MerchantName!: string;
    Category!: string;
    Website!: string;
    SocialNetwork!: string;
    OwnerNationalID!: string;
    ContactName!: string;
    SaveChanges!: string;
    Add!: string;

    //create Award Offer

    CreateOfferTitle!: string;
    OfferDescription!: string;
    OfferDuration!: string;
    OfferImage!: string;
    AwardVisibility!: string;
    AwardValidity!: string;
    OfferFacets!: string;
    LoyaltyAccount!: string;
    PointsRequired!: string;
    ProductCost!: string;
    CommonDealCode!: string;
    UploadExternalIDs!: string;
    LimitPerMember!: string;
    MaximumUsageLimit!: string;
    SelectPeriod!: string;
    CommunicationControls!: string;
    CommunicationSchedule!: string;
    PreviewOffer!: string;
    LocationName!: string;
    NoLocationSelected!: string;
    BackToEdit!: string;
    SubmitOffer!: string;

}


export class genericEntryIdObj {
  	entryIds = [
        { id: '72AjJjXeh7zszJC1Vv6j4D', text: 'LogIn'},
        { id: '4KddYJ81LvqfqnEnwiyisq', text: 'BecomePartner'},
        { id: 'wxUvww8F2QFm1dTJUp5NE', text: 'ForgetPassword'},
        { id: '4rODLTgJeMc4rJ18USgxNv', text: 'Email'},
        { id: '5JzJfO0LKnLDBe8WSUunKH', text: 'Password'},
        { id: '5j3Wgwh1yQvtcAaTNvoOWD', text: 'EnterEmail'},
        { id: '1QAkIYIL3drbuP9v3N98sW', text: 'Cancel'},
        { id: '6YJuhjR69LTrwIqip3qQWG', text: 'Close'},
        { id: '3Juv0uXAX5TkP4F6sGAtm0', text: 'Submit'},
        { id: '6XopNBO80kMVOZsrW3FxCA', text: 'EnterNewPassword'},
        { id: '625NitMom4npXHNCcLBSlw', text: 'VerifyNewPassword'},
        { id: '1d5EJ6rPnEb5GXnWjlmcJh', text: 'Proceed'},
        { id: '1pWdZ73MhGW71YpvEWM4MT', text: 'Continue'},
        { id: '3qqfGM28Pxco5YyuTLUJAY', text: 'Done'},
        { id: 'oow4IOhuL3RaWt3HH50cv', text: 'Search'},
        { id: '3qJLGmer7QPf67j83Yt2ib', text: 'Next'},
        { id: 'SKzNKw6xouGuVIwkRm8Yb', text: 'Previous'},
        { id: '2ULu5fbKQGJTLXaxQ3ElHi', text: 'Country'},
        { id: '1DIXx9wlOpLmUY5nOwkIBZ', text: 'City'},
        { id: '4ON1LnjQFSVQmygLTgjZgP', text: 'Address'},

        //onboarding labels
        { id: '7mXM5Epdo93iZp21teK150', text: 'Title'},
        { id: '1izUGsKaM5s2fy34psIrQ0', text: 'FirstName'},
        { id: '7qim0j4WBUgv7LV4WbhtzP', text: 'LastName'},
        { id: '46Wn73QM6E6FeVtIjjvtBD', text: 'Phone'},
        { id: '5UkAwNDJavqWVKuU6Ka4mx', text: 'ConfirmPassword'},
        { id: '5r1WfYOTqgrBBxi5d4mMK6', text: 'CommunicationChannel'},
        { id: '2k21Mn17eAu06vFZgF81Ud', text: 'EntityType'},
        { id: '5BRsywQDS8Nc1J4ogXY62w', text: 'BusinessName'},
        { id: '7DWvxOyph4sYEd1Am9AQCY', text: 'TradingName'},
        { id: '6oyMrl5IHKQAgbLyUmLICj', text: 'Industry'},
        { id: '17thjg5KLcQrCup7R5Bce2', text: 'RegistrationNumber'},
        { id: '2GzlVMVktI7OVPmZ789zGM', text: 'NationalID'},
        { id: '7J7jY3lSvR5CVW1cQLhoy7', text: 'passwordLengthHint'},
        { id: 'MFKVQTH1ov4b5Pq5eYXkb', text: 'passwordLowercaseHint'},
        { id: '7m6xIEhp1Mt3YcPrac4Nvy', text: 'passwordUppercaseHint'},
        { id: '1bsg9rUnsqe0aoNP3N7ceS', text: 'passwordNumbercaseHint'},
        { id: '1VdRHM21x23e0NCGWZt7l1', text: 'passwordSpecialHint'},
        { id: '7IflsxZRHic8EmYXRPH9oo', text: 'PasswordNotMatch'},
        { id: 'QAhkXaWZAbXJb38cqaWWr', text: 'EnterValidEmail'},

        //common Component
        { id: '2DK3pkWXnChMEWIKstBPsq', text: 'SortAndFilter'},
        { id: '6aEVa4qhVaV3WoX0DjEiCR', text: 'SortBy'},
        { id: '3Z31VzeXOjD1sFMYmoxU1k', text: 'FilterData'},
        { id: '5FdEwHSw4rtYdXGIznYjwY', text: 'Apply'},

        //offers Labels
        { id: '3mAWpKJOa0uZ8Za1yyXXUm', text: 'Offers'},
        { id: '2OULjUNrm6LT3erT4LLz0p', text: 'OfferTitle'},
        { id: 'vEpTp85jG6KAWoFdPPl8I', text: 'OfferType'},
        { id: '6OqF0jXoRifR43iLZkun87', text: 'StartDate'},
        { id: '5JRCAiLeQQui2MCKxIk2Ci', text: 'ExpirationDate'},
        { id: '3a9sw1r6tnsk0t8rOlWvud', text: 'Status'},

        //profile
        { id: '2iYkZUVCwq5HmTJltrE762', text: 'ResetPassword'},
        { id: '9UYqGIm5tW57B64RPFiCu', text: 'Reset2FA'},
        { id: '7IrXN6nEAdCVUKfFXS9rXt', text: 'JoiningDate'},
        { id: 'qahuODFzGjllPYTnq2Mwn', text: 'MerchantName'},
        { id: '23Rs7Wm8GaIf16Z8TWJFe1', text: 'Category'},
        { id: '5a6jnboqoMtC5DVPi1QTo0', text: 'Website'},
        { id: '3xyaGh13Sxj2w2dgGFx6z8', text: 'SocialNetwork'},
        { id: '2G3Any8vveK2eHR6ZjvjSM', text: 'OwnerNationalID'},
        { id: '5X6PjzXHwUtLEW2XJ89AX3', text: 'ContactName'},
        { id: '2UOktEd64iM6xUmDcPDCh2', text: 'SaveChanges'},
        { id: '7K1BSIabZcFlmU2C7Wq8BA', text: 'Add'},

        //create Award Offer
        { id: '6yJKqgZYm6FNU6FOUwmGvI', text: 'CreateOfferTitle'},
        { id: '4maL2uJwQ0xaVmYmzHfA4g', text: 'OfferDescription'},
        { id: 'yQGlCJiI62SKbplmW76Tl', text: 'OfferDuration'},
        { id: '3IhpGAbNGi4IRPiZQ8dRDG', text: 'OfferImage'},
        { id: '2TrHOqRPzBkoIFtnyqiLLj', text: 'AwardVisibility'},
        { id: '55uABCYdhJd2P1PtHG8l0I', text: 'AwardValidity'},
        { id: '5cwoHmRFgbBgePKPofc7tl', text: 'OfferFacets'},
        { id: '1oT6vCsRyTK9oyUMgPZ5Q9', text: 'LoyaltyAccount'},
        { id: '2LM21hFGcT1jzzUgwNK22O', text: 'PointsRequired'},
        { id: '3iOYfgheXZA1ZRisRpd6XA', text: 'ProductCost'},
        { id: '3kH7TuGz6xcIX5dWvHiDXH', text: 'CommonDealCode'},
        { id: '76fzRtHIUpOe8Kwqin8Bpd', text: 'UploadExternalIDs'},
        { id: '2HRSPesaSNn8WHhxfQqP4f', text: 'LimitPerMember'},
        { id: 'vIN2K65ztklNVUztwiInK', text: 'MaximumUsageLimit'},
        { id: '3G9xkBN6IBENxVUst5m83C', text: 'SelectPeriod'},
        { id: '2KecH2oD5vv0Qf5cNQ1WCO', text: 'CommunicationControls'},
        { id: '4BEh5A25sCHMrlfeUeOqd3', text: 'CommunicationSchedule'},
        { id: '3XE4v3SgOOxB9pjr6bIPJ4', text: 'PreviewOffer'},
        { id: '7tI8dKpGslK7uyBhcTXxLi', text: 'LocationName'},
        { id: '5Rk4E4rthSGUD9DKxZClJ1', text: 'NoLocationSelected'},
        { id: '1tuRDB4fBDtfpko9DtNHRJ', text: 'BackToEdit'},
        { id: '7HVNYDD6YXN9U51Ktm0YEy', text: 'SubmitOffer'},
  	];
    
}