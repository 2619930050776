import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransferSelect } from '../../interfaces/transfer-select.interface';
import { TransferSelectComponent } from '../transfer-select/transfer-select.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-select-facets-field',
  templateUrl: './select-facets-field.component.html',
  styleUrl: './select-facets-field.component.less',
})
export class SelectFacetsFieldComponent {
  @Input() fieldTitle: string = 'Offer Facets';
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() values: string[] = [];
  @Input() width: number = 100;
  @Input() widthUnit: 'px' | '%' | 'rem' | 'em' = '%';
  @Input() facets: { key: string; value: string }[] = [
    {
      key: '1',
      value: 'Afsdf',
    },
    {
      key: '2',
      value: 'Bdsfsd',
    },
    {
      key: '3',
      value: 'Cddsfsdf',
    },
  ];
  @Input() errorMessage: string = '';
  @Input() showError: boolean = true;
  @Input() disabled: boolean = false;
  @Input() readOnly: boolean = false;
  @Output() valuesChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(private matDialog: MatDialog) {}

  ngOnInit(): void {}

  getFacetMapping() {
    let facets: string[] = [...(this.values ?? [])];
    if (facets.length > 0) {
      facets = this.values?.map((value) => {
        const facet = this.facets?.find((facet) => facet.key == value);
        if (facet) {
          return facet.value;
        }
        return value;
      });
      return facets.join(', ');
    }
    return '';
  }

  openTransferSelectModal() {
    const dialogRef = this.openTransferSelect({
      width: '600px',
      title: 'Manage Facets',
      availableTitle: 'Available Facets',
      options: [...this.facets],
      selectedTitle: 'Selected Facets',
      values: [...(this.values ?? [])],
    });
    dialogRef.afterClosed().subscribe((data: string[]) => {
      if(data){
        this.values = [...data];
        this.valuesChange.emit([...this.values]);
      }
      
    });
  }

  openTransferSelect(payload: TransferSelect) {
    return this.matDialog.open(TransferSelectComponent, {
      backdropClass: ['default-dialog-backdrop', 'hidable-backdrop'],
      panelClass: ['transfer-select-panel', 'hidable-panel'],
      width: payload.width,
      data: payload,
      hasBackdrop: true,
      disableClose: true,
      ariaLabel: 'Facets transfer select model',
    });
  }
}
