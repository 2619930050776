<div
  class="info-tooltip"
  [showOnHover]="true"
  [closeOnMouseLeave]="true"
  gravtyToolTip
  [ngStyle]="{
    width: iconSize + 'px',
    height: iconSize + 'px',
    backgroundImage: 'url(' + iconUrl + ')'
  }"
  (open)="onOpen()"
  (close)="onClose()"
  [attr.tabindex]="disabled ? '-1' : '0'"
  role="tooltip"
  aria-label="info-tooltip"
  (focus)="onOpen()"
  (blur)="onClose()"
>
  <div
    class="info-tooltip__wrapper {{ alignToolTip }}"
    [ngStyle]="{
      width: tooltipWidth + 'px',
      height: enableCustomDesign ? 'fit-content' : tooltipHeight + 'px'
    }"
  >
    <ng-container *ngIf="enableCustomDesign; else defaultDesign">
      <ng-content></ng-content>
    </ng-container>
    <ng-template #defaultDesign>
      <ng-container *ngIf="!showLoader; else loader">
        <ul class="info-tooltip__wrapper-content" *ngFor="let section of info">
          <li
            class="info-tooltip__wrapper-content__item"
            *ngFor="let item of section"
          >
            <div class="info-tooltip__wrapper-content__item-label">
              {{ item.label }}:
            </div>
            <div
              class="info-tooltip__wrapper-content__item-value"
              [title]="item.value"
            >
              {{ item.value }}
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-template #loader>
        <div class="info-tooltip__wrapper__loader">
          <img
            src="/assets/img/ring.gif"
            alt="Loader"
            class="info-tooltip__wrapper__loader-icon"
          />
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
