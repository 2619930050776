<div class="document-upload-container" 
     [ngClass]="{'document-upload-container__rounded':rounded, 'document-upload-container__readOnly':readOnly}">
  <label for="desktop-upload{{fieldId}}" class="document-upload__label">
    <div class="document-upload">
      <div
        class="document-upload__item"
        [ngStyle]="{ width: width + widthUnit, height: height + heightUnit, 'background-image': 'url(' + uplodedDocumentUrl + ')' }"
      >
        <input
          id="desktop-upload{{fieldId}}"
          type="file"
          accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.rtf,.odt,.ods,.odp,.html,.xml,.json,.zip"
          (change)="onClick($event)"
          hidden
        />
        <img [src]="uplodedDocumentUrl ? '/assets/images/icons/doc_placeholder.png' : '/assets/images/icons/doc_placeholder.png'" alt="Desktop Preview" class="image-preview" 
        [ngClass]="{'image-upload__item__uploded':uplodedDocumentUrl,
            'image-upload__item__uploded--rounded':uplodedDocumentUrl && rounded }"/>
      </div>
    </div>
  </label>
</div>




