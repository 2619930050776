<div class="alert-dialog">
  <div class="alert-dialog__header {{ data.type }}">
    <img
      [src]="data.icon"
      [alt]="data.title"
      class="alert-dialog__header-icon"
    />
    <h5 class="alert-dialog__header-title">{{ data.title }}</h5>
  </div>
  <div class="alert-dialog__body">
    <p class="alert-dialog__body-text" *ngFor="let message of data.body">
      {{ message }}
    </p>
    <img class="alert-dialog__body-imageloader" *ngIf="data.isImageLoader" src="{{data.imageSrc}}" alt="loader-img">
  </div>
  <div class="alert-dialog__footer">
    <app-button
      [displayText]="action.displayText"
      [type]="action.type"
      [showBorder]="false"
      [width]="action.width"
      (clicked)="closeDialog(action.key)"
      [height]="action.height"
      *ngFor="let action of data.actions"
    ></app-button>
  </div>
</div>

