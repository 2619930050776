import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.less',
})
export class IconButtonComponent {
  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() type: 'add' | 'remove' | 'cross' | 'check' | string = 'add';
  @Input() size: number = 30;
  @Input() iconHeight: number = 13.64;
  @Input() iconWidth: number = 13.64;
  constructor() {}

  ngOnInit(): void {}
  onClick() {
    this.clicked.emit();
  }

  get buttonUrl(): string {
    switch (this.type) {
      case 'add':
        return '../../../../../assets/images/icons/add@2x.png';
      case 'remove':
        return '../../../../../assets/images/icons/remove@2x.png';
      case 'cross':
        return '../../../../../assets/images/icons/cross@2x.png';
      case 'check':
        return '../../../../../assets/images/icons/checkmark@2x.png';
      default:
        return '';
    }
  }
}
