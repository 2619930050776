import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadService } from '../../services/file-upload.service';
import { SharedService } from '../../../../shared/shared.service';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrl: './upload-document.component.less',
})
export class UploadDocumentComponent {
  private host = environment.host();
  @Input() description: string = '';
  @Input() showDescription: boolean = true;
  @Input() width: number = 100;
  @Input() widthUnit: 'px' | '%' | 'rem' | 'em' = 'px';
  @Input() height: number = 100;
  @Input() heightUnit: 'px' | '%' | 'rem' | 'em' = 'px';
  @Input() uploadTitle: string = 'Upload File';
  @Output() uploadedDocument = new EventEmitter();
  @Output() emitErrorMessage = new EventEmitter();
  @Input() fileNameText: string = 'No files selected';
  @Input() subfolder: string = '/sponsor/document';
  @Input() imageWidth: string = '80px';
  @Input() imageHeight: string = '80px';
  @Input() imageRadius: string = '50%';
  @Input() helpText?: string;
  @Input() fieldTitle?: string;
  @Input() required: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() showbackdrop: boolean = false;
  @Input() isBanner: boolean = false;
  @Input() margin: string = '0%';
  @Input() isObjectFitCover: boolean = false;
  @Input() deviceTypeName?: string;
  @Input() uplodedDocumentUrl?: string;
  @Input() rounded: boolean = false;
  @Input() backgroundColor: string = '#FFFFFF';
  fileDetails: any = {};
  error: boolean = false;
  errorMessage: string = '';
  fieldId = Date.now();
  changeEvent: any;
  fileName: any;
  fileType: any;
  sponsorId !: string;

  constructor(
    private fileUploadService: FileUploadService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.sponsorId = this.sharedService.currentSponsorId;
  }
  onClick(event: any) {
    this.changeEvent = event;
    const file: File = event.target.files[0];
    this.fileName = file.name;
    this.fileType = file.type;
    this.fileUploadService.uploadDocumentFile(this.changeEvent, this);
  }
  uploadDocument(scope?: any) {
    scope = this;
    let params = {
      file_name: this.fileName,
      file_type: this.fileType,
      subfolder: this.subfolder,
      file_size_in_kb: scope.file.size,
      tag: this.fileName.split(".")[0],
      category: 'CUSTOM',
      description: ''
    };
    this.fileUploadService.uploadDocument(this.sponsorId, params, this.host).subscribe({
      next: (data: any) => {
        this.postFile(data, params, scope);
      },
      error: (reason: any) => {
        this.emitErrorMessage.emit(reason.error);
      },
    });
  }
  postFile(val: any, request: any, scope?: any) {
    this.sharedService.toggleGlobalLoader(true);
    let xhr = new XMLHttpRequest();
    xhr.open('PUT', val.signed_request);
    xhr.setRequestHeader('Content-Type', request.file_type);
    // xhr.setRequestHeader('x-amz-acl', 'private');
    xhr.onload = () => {
      if (xhr.status === 200) {
        this.sharedService.toggleGlobalLoader(false);
        this.uploadedDocument.emit(val.url);
      }
    };
    xhr.onerror = () => {
      this.sharedService.toggleGlobalLoader(false);
    };
    let imageUrl: any = scope.file.data;
    let blob: any = this.dataURItoBlob(imageUrl);
    xhr.send(blob);
  }
  dataURItoBlob(dataURI?: any) {
    let byteString: any = atob(dataURI.split(',')[1]);
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
}
