import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any[], ...args: any[]): any[] {
    const filters = args[0];
    const keys = Object.keys(filters);
    const filteredList = value.filter((val) => {
      return keys.some((key) =>
        val[key].toLowerCase().includes(filters[key].toLowerCase())
      );
    });
    return [...filteredList];
  }
}
