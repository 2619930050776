import { encodeCPAResponse } from '@contentful/content-source-maps';
import { createRequestConfig, errorHandler } from 'contentful-sdk-core';
import pagedSync from './paged-sync.js';
import normalizeSearchParameters from './utils/normalize-search-parameters.js';
import normalizeSelect from './utils/normalize-select.js';
import resolveCircular from './utils/resolve-circular.js';
import getQuerySelectionSet from './utils/query-selection-set.js';
import validateTimestamp from './utils/validate-timestamp.js';
import { validateLocaleParam, validateResolveLinksParam, validateRemoveUnresolvedParam, checkIncludeContentSourceMapsParamIsAllowed } from './utils/validate-params.js';
import validateSearchParameters from './utils/validate-search-parameters.js';

/**
 * Contentful Delivery API Client. Contains methods which allow access to the
 * different kinds of entities present in Contentful (Entries, Assets, etc).
 */
const ASSET_KEY_MAX_LIFETIME = 48 * 60 * 60;
class NotFoundError extends Error {
  constructor(id, environment, space) {
    super('The resource could not be found.');
    this.sys = {
      type: 'Error',
      id: 'NotFound'
    };
    this.details = {
      type: 'Entry',
      id,
      environment,
      space
    };
  }
}
function createContentfulApi({
  http,
  getGlobalOptions
}, options) {
  const notFoundError = (id = 'unknown') => {
    return new NotFoundError(id, getGlobalOptions().environment, getGlobalOptions().space);
  };
  const getBaseUrl = context => {
    let baseUrl = context === 'space' ? getGlobalOptions().spaceBaseUrl : getGlobalOptions().environmentBaseUrl;
    if (!baseUrl) {
      throw new Error('Please define baseUrl for ' + context);
    }
    if (!baseUrl.endsWith('/')) {
      baseUrl += '/';
    }
    return baseUrl;
  };
  function maybeEnableSourceMaps(query = {}) {
    var _a, _b;
    const params = http.httpClientParams;
    const includeContentSourceMaps = (_a = params === null || params === void 0 ? void 0 : params.includeContentSourceMaps) !== null && _a !== void 0 ? _a : (_b = params === null || params === void 0 ? void 0 : params.alphaFeatures) === null || _b === void 0 ? void 0 : _b.includeContentSourceMaps;
    const host = params === null || params === void 0 ? void 0 : params.host;
    const areAllowed = checkIncludeContentSourceMapsParamIsAllowed(host, includeContentSourceMaps);
    if (areAllowed) {
      query.includeContentSourceMaps = true;
      // Ensure that content source maps and required attributes are selected
      if (query.select) {
        const selection = getQuerySelectionSet(query);
        selection.add('sys');
        query.select = Array.from(selection).join(',');
      }
    }
    return query;
  }
  function maybeEncodeCPAResponse(data, config) {
    var _a;
    const includeContentSourceMaps = (_a = config === null || config === void 0 ? void 0 : config.params) === null || _a === void 0 ? void 0 : _a.includeContentSourceMaps;
    if (includeContentSourceMaps) {
      return encodeCPAResponse(data);
    }
    return data;
  }
  async function get({
    context,
    path,
    config
  }) {
    const baseUrl = getBaseUrl(context);
    try {
      const response = await http.get(baseUrl + path, config);
      return maybeEncodeCPAResponse(response.data, config);
    } catch (error) {
      errorHandler(error);
    }
  }
  async function post({
    context,
    path,
    data,
    config
  }) {
    const baseUrl = getBaseUrl(context);
    try {
      const response = await http.post(baseUrl + path, data, config);
      return response.data;
    } catch (error) {
      errorHandler(error);
    }
  }
  async function getSpace() {
    return get({
      context: 'space',
      path: ''
    });
  }
  async function getContentType(id) {
    return get({
      context: 'environment',
      path: `content_types/${id}`
    });
  }
  async function getContentTypes(query = {}) {
    return get({
      context: 'environment',
      path: 'content_types',
      config: createRequestConfig({
        query
      })
    });
  }
  async function getEntry(id, query = {}) {
    return makeGetEntry(id, query, options);
  }
  async function getEntries(query = {}) {
    return makeGetEntries(query, options);
  }
  async function makeGetEntry(id, query, options = {
    withAllLocales: false,
    withoutLinkResolution: false,
    withoutUnresolvableLinks: false
  }) {
    const {
      withAllLocales
    } = options;
    validateLocaleParam(query, withAllLocales);
    validateResolveLinksParam(query);
    validateRemoveUnresolvedParam(query);
    validateSearchParameters(query);
    return internalGetEntry(id, withAllLocales ? Object.assign(Object.assign({}, query), {
      locale: '*'
    }) : query, options);
  }
  async function internalGetEntry(id, query, options) {
    if (!id) {
      throw notFoundError(id);
    }
    try {
      const response = await internalGetEntries(Object.assign({
        'sys.id': id
      }, maybeEnableSourceMaps(query)), options);
      if (response.items.length > 0) {
        return response.items[0];
      } else {
        throw notFoundError(id);
      }
    } catch (error) {
      errorHandler(error);
    }
  }
  async function makeGetEntries(query, options = {
    withAllLocales: false,
    withoutLinkResolution: false,
    withoutUnresolvableLinks: false
  }) {
    const {
      withAllLocales
    } = options;
    validateLocaleParam(query, withAllLocales);
    validateResolveLinksParam(query);
    validateRemoveUnresolvedParam(query);
    validateSearchParameters(query);
    return internalGetEntries(withAllLocales ? Object.assign(Object.assign({}, query), {
      locale: '*'
    }) : query, options);
  }
  async function internalGetEntries(query, options) {
    const {
      withoutLinkResolution,
      withoutUnresolvableLinks
    } = options;
    try {
      const entries = await get({
        context: 'environment',
        path: 'entries',
        config: createRequestConfig({
          query: maybeEnableSourceMaps(normalizeSearchParameters(normalizeSelect(query)))
        })
      });
      return resolveCircular(entries, {
        resolveLinks: !withoutLinkResolution,
        removeUnresolved: withoutUnresolvableLinks !== null && withoutUnresolvableLinks !== void 0 ? withoutUnresolvableLinks : false
      });
    } catch (error) {
      errorHandler(error);
    }
  }
  async function getAsset(id, query = {}) {
    return makeGetAsset(id, query, options);
  }
  async function getAssets(query = {}) {
    return makeGetAssets(query, options);
  }
  async function makeGetAssets(query, options = {
    withAllLocales: false,
    withoutLinkResolution: false,
    withoutUnresolvableLinks: false
  }) {
    const {
      withAllLocales
    } = options;
    validateLocaleParam(query, withAllLocales);
    validateSearchParameters(query);
    const localeSpecificQuery = withAllLocales ? Object.assign(Object.assign({}, query), {
      locale: '*'
    }) : query;
    return internalGetAssets(localeSpecificQuery);
  }
  async function internalGetAsset(id, query) {
    try {
      return get({
        context: 'environment',
        path: `assets/${id}`,
        config: createRequestConfig({
          query: maybeEnableSourceMaps(normalizeSelect(query))
        })
      });
    } catch (error) {
      errorHandler(error);
    }
  }
  async function makeGetAsset(id, query, options = {
    withAllLocales: false,
    withoutLinkResolution: false,
    withoutUnresolvableLinks: false
  }) {
    const {
      withAllLocales
    } = options;
    validateLocaleParam(query, withAllLocales);
    validateSearchParameters(query);
    const localeSpecificQuery = withAllLocales ? Object.assign(Object.assign({}, query), {
      locale: '*'
    }) : query;
    return internalGetAsset(id, localeSpecificQuery);
  }
  async function internalGetAssets(query) {
    try {
      return get({
        context: 'environment',
        path: 'assets',
        config: createRequestConfig({
          query: maybeEnableSourceMaps(normalizeSearchParameters(normalizeSelect(query)))
        })
      });
    } catch (error) {
      errorHandler(error);
    }
  }
  async function getTag(id) {
    return get({
      context: 'environment',
      path: `tags/${id}`
    });
  }
  async function getTags(query = {}) {
    validateSearchParameters(query);
    return get({
      context: 'environment',
      path: 'tags',
      config: createRequestConfig({
        query: normalizeSearchParameters(normalizeSelect(query))
      })
    });
  }
  async function createAssetKey(expiresAt) {
    try {
      const now = Math.floor(Date.now() / 1000);
      const currentMaxLifetime = now + ASSET_KEY_MAX_LIFETIME;
      validateTimestamp('expiresAt', expiresAt, {
        maximum: currentMaxLifetime,
        now
      });
    } catch (error) {
      errorHandler(error);
    }
    return post({
      context: 'environment',
      path: 'asset_keys',
      data: {
        expiresAt
      }
    });
  }
  async function getLocales(query = {}) {
    validateSearchParameters(query);
    return get({
      context: 'environment',
      path: 'locales',
      config: createRequestConfig({
        query: normalizeSelect(query)
      })
    });
  }
  async function sync(query, syncOptions = {
    paginate: true
  }) {
    return makePagedSync(query, syncOptions, options);
  }
  async function makePagedSync(query, syncOptions, options = {
    withAllLocales: false,
    withoutLinkResolution: false,
    withoutUnresolvableLinks: false
  }) {
    validateResolveLinksParam(query);
    validateRemoveUnresolvedParam(query);
    const combinedOptions = Object.assign(Object.assign({}, syncOptions), options);
    switchToEnvironment(http);
    return pagedSync(http, query, combinedOptions);
  }
  function parseEntries(data) {
    return makeParseEntries(data, options);
  }
  function makeParseEntries(data, options = {
    withAllLocales: false,
    withoutLinkResolution: false,
    withoutUnresolvableLinks: false
  }) {
    return internalParseEntries(data, options);
  }
  function internalParseEntries(data, options) {
    const {
      withoutLinkResolution,
      withoutUnresolvableLinks
    } = options;
    return resolveCircular(data, {
      resolveLinks: !withoutLinkResolution,
      removeUnresolved: withoutUnresolvableLinks !== null && withoutUnresolvableLinks !== void 0 ? withoutUnresolvableLinks : false
    });
  }
  /*
   * Switches BaseURL to use /environments path
   * */
  function switchToEnvironment(http) {
    http.defaults.baseURL = getGlobalOptions().environmentBaseUrl;
  }
  return {
    version: "11.0.3",
    getSpace,
    getContentType,
    getContentTypes,
    getAsset,
    getAssets,
    getTag,
    getTags,
    getLocales,
    parseEntries,
    sync,
    getEntry,
    getEntries,
    createAssetKey
  };
}
export { createContentfulApi as default };