import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-global-error',
  templateUrl: './global-error.component.html',
  styleUrl: './global-error.component.less',
})
export class GlobalErrorComponent {
  @Input() apiError?: any;
  @Input() enableFieldLevel: boolean = false;
  @Input() marginZero: boolean = false;
  @Output() apiErrorChange = new EventEmitter();

  closeError() {
    this.apiError = null;
    this.apiErrorChange.emit(null);
  }

  constructor(private translate: TranslateService) {}

  get errorMessage() {
    if (this.apiError) {
      return this.ShowValidationErrors(this.apiError) ?? '';
    }
    return '';
  }
  ShowValidationErrors(
    reason?: any,
    scope?: any,
    formname?: any,
    pagename?: any,
    error_field?: any,
    code?: any
  ) {
    if (scope == undefined) {
      scope = {};
    } else {
      scope.submitted = true;
      // window.scrollTo(0, 0);
      scope.costArray = [];
    }

    if (
      reason != undefined &&
      reason !== null &&
      reason.error != undefined &&
      Object.keys(reason.error).length > 0
    ) {
      if (reason.error?.device_mac !== undefined) {
        scope.error = true;
        scope.errorMsg = reason.error.device_mac[0].message;
      }
      if (
        (reason.error.type != undefined && reason.error.type === 'ERROR') ||
        (reason.error.code != undefined &&
          (reason.error.code === 'permission_denied' ||
            reason.error.code === 'authentication_failed')) ||
        (reason.error.error?.code != undefined &&
          (reason.error.error.code === 'permission_denied' ||
            reason.error.error.code === 'authentication_failed'))
      ) {
        if (error_field) {
          scope[error_field] = true;
        } else {
          scope.error = true;
        }
        scope.errorMsg = reason.error.message;
      } else if (
        reason.error?.alert_conditions != undefined &&
        reason.error?.alert_conditions[0]?.code === 'invalid_alert_conditions'
      ) {
        scope.error = true;
        scope.errorMsg = reason.error.alert_conditions[0].message;
      } else if (reason.error?.alert_threshold != undefined) {
        scope.error = true;
        scope.errorMsg = reason.error.alert_threshold[0].message;
      } else if (reason.error?.operator != undefined) {
        scope.error = true;
        scope.errorMsg = reason.error.operator[0].message;
      } else if (
        reason.error.name &&
        reason.error.name != null &&
        reason.error.name[0] &&
        reason.error.name[0] != null &&
        reason.error.name[0] != undefined
      ) {
        scope.error = true;
        scope.errorMsg = reason.error.name[0].message;
      } else if (
        reason.error.title &&
        reason.error.title != null &&
        reason.error.title[0] &&
        reason.error.title[0] != null &&
        reason.error.title[0] != undefined
      ) {
        scope.error = true;
        scope.errorMsg = reason.error.title[0].message;
      } else if (
        reason.error.description &&
        reason.error.description != null &&
        reason.error.description[0] &&
        reason.error.description[0] != null &&
        reason.error.description[0] != undefined
      ) {
        scope.error = true;
        scope.errorMsg = reason.error.description[0].message;
      } else if (
        (reason.error.code != undefined &&
          reason.error.code === 'integrity_error') ||
        (reason.error.code != undefined && reason.error.code === 'throttled')
      ) {
        scope.error = true;
        scope.errorMsg = reason.error.message;
      } else if (
        (reason.error.code != undefined &&
          reason.error.code === 'datalake_call_failed') ||
        (reason.error.code != undefined && reason.error.code === 'not_found')
      ) {
        scope.error = true;
        scope.errorMsg = reason.error.message;
      } else if (
        reason.error.code != undefined &&
        reason.error.code === 'method_not_allowed'
      ) {
        scope.error = true;
        scope.errorMsg = reason.error.message;
      } else if (
        reason.error.offer_name != undefined &&
        reason.error.offer_name !== null &&
        reason.error.offer_name[0].code === 'max_length'
      ) {
        scope.error = true;
        let errorList = [];
        for (let i = 0; i < reason.error.offer_name.length; i++) {
          errorList.push(reason.error.offer_name[i].message);
        }
        scope.errorMsg =
          this.translate.instant('OFFER_TITLE') + ' : ' + errorList.join();
      } else if (
        reason.error.contact_person_name != undefined &&
        reason.error.contact_person_name !== null
      ) {
        scope.error = true;
        let errorList = [];
        for (let i = 0; i < reason.error.contact_person_name.length; i++) {
          errorList.push(reason.error.contact_person_name[i].message);
        }
        scope.errorMsg =
          this.translate.instant('CONTACT_PERSPON') + ' : ' + errorList.join();
      } else if (
        reason.error.email != undefined &&
        reason.error.email !== null
      ) {
        scope.error = true;
        let errorList = [];
        for (let i = 0; i < reason.error.email.length; i++) {
          errorList.push(reason.error.email[i].message);
        }
        if (!errorList.length && reason.error.email.message) {
          if (
            reason.error.email.message[0] === '[' &&
            reason.error.email.message[1] === "'"
          ) {
            reason.error.email.message = reason.error.email.message.substr(2);
          }
          errorList.push(reason.error.email.message);
        }
        if (
          reason.error.email[0] &&
          (reason.error.email[0].code === 'email_exists' ||
            reason.error.email[0].code === 'unique')
        ) {
          scope.errorMsg = reason.error.email[0].message.replace(
            /[\[\]']+/g,
            ''
          );
        } else {
          scope.errorMsg =
            this.translate.instant('EMAIL') + ' : ' + errorList.join();
        }
      } else if (
        reason.error.billing_plan != undefined &&
        reason.error.billing_plan !== null &&
        reason.error.billing_plan.rate[0].code === 'max_decimal_places'
      ) {
        scope.error = true;
        scope.errorMsg = reason.error.billing_plan.rate[0].message;
      } else if (
        reason.error.confirmation_number != undefined &&
        reason.error.confirmation_number !== null
      ) {
        scope.error = true;
        scope.errorMsg = reason.error.confirmation_number[0].message;
      } else if (
        reason.error.location_timings != undefined &&
        reason.error.location_timings !== null &&
        reason.error.location_timings.length > 0
      ) {
        scope.error = true;
        if (
          reason.error.location_timings[0].close_time != undefined &&
          reason.error.location_timings[0].close_time !== null &&
          reason.error.location_timings[0].close_time.length > 0
        ) {
          scope.errorMsg =
            reason.error.location_timings[0].close_time[0].message;
        }
        if (
          reason.error.location_timings[0].open_time != undefined &&
          reason.error.location_timings[0].open_time !== null &&
          reason.error.location_timings[0].open_time.length > 0
        ) {
          scope.errorMsg =
            reason.error.location_timings[0].open_time[0].message;
        }
      } else if (
        reason.error.category != undefined &&
        reason.error.category !== null &&
        reason.error.category.length > 0 &&
        pagename === 'product' &&
        formname === 'productform'
      ) {
        let errorList = [];
        for (let i = 0; i < reason.error.category.length; i++) {
          errorList.push(
            reason.error.category[i].message.split("['")[1].split("']")[0]
          );
        }
        scope.error = true;
        scope.errorMsg =
          this.translate.instant('PRODUCT_CATGEORY') + ' : ' + errorList.join();
      } else if (formname === 'alertUpload') {
        if (reason.error.description != undefined) {
          scope.error = true;
          scope.errorMsg =
            'Alert Description : ' + reason.error.description[0].message;
        } else if (reason.error.visible_name != undefined) {
          scope.error = true;
          scope.errorMsg =
            'Alert Name : ' + reason.error.visible_name[0].message;
        } else if (reason.error.invalid_retrigger_after != undefined) {
          scope.error = true;
          scope.errorMsg = reason.error.invalid_retrigger_after[0].message;
        } else if (reason.error.name != undefined) {
          scope.error = true;
          scope.errorMsg = reason.error.name[0].message;
        } else if (reason.error.invalid_interval_period != undefined) {
          scope.error = true;
          scope.errorMsg =
            reason.error.invalid_interval_period[0].message.replace("']", '');
        } else if (reason.error.evaluation_period != undefined) {
          scope.error = true;
          scope.errorMsg = reason.error.evaluation_period[0].message.replace(
            "']",
            ''
          );
        } else if (reason.error.invalid_evaluation_period != undefined) {
          scope.error = true;
          scope.errorMsg =
            reason.error.invalid_evaluation_period[0].message.replace("']", '');
        } else if (reason.error.param != undefined) {
          scope.error = true;
          scope.errorMsg = reason.error.param[0].message
            .replace("']", '')
            .replace("['", '');
        } else if (reason.error.invalid_start_evaluating_from != undefined) {
          scope.error = true;
          scope.errorMsg = reason.error.invalid_start_evaluating_from[0].message
            .replace("']", '')
            .replace("['", '');
        }
      } else {
        Object.keys(reason.error).forEach((key: any) => {
          let value = reason.error[key];
          if (key == 0) {
            if (error_field) {
              scope[error_field] = true;
            } else {
              scope.error = true;
            }
            if (
              reason.error[0].code === 'sponsor_base_rate_creation_failed' ||
              reason.error[0].code === 'cannot_create_base_rate' ||
              reason.error[0].code === 'password_pwned'
            ) {
              if (reason.error[0].message.split("['")[1] != undefined) {
                scope.errorMsg = reason.error[0].message
                  .split("['")[1]
                  .split("']")[0];
              } else {
                scope.errorMsg = reason.error[0].message;
              }
            } else if (
              reason.error[0].code === 'cannot_disable_member_attribute'
            ) {
              if (reason.error[0].message.split('[')[1] != undefined) {
                let temp = reason.error[0].message.split('[');
                let forms = temp[1].split(',');
                scope.errorMsg =
                  temp[0] + forms.join(',').slice(0, -1).replace(/\'/g, '');
              } else {
                scope.errorMsg = reason.error[0].message;
              }
            } else if (
              reason.error[0].message != undefined &&
              reason.error[0].message !== '' &&
              reason.error[0].message !== null
            ) {
              scope.error = true;
              scope.errorMsg = reason.error[0].message;
            } else {
              scope.errorArray = [];
              if (reason.error.length > 0) {
                for (let i = 0; i < reason.error.length; i++) {
                  scope.errorArray.push(reason.error[i].message);
                }
              }
              scope.errorMsg = scope.errorArray
                .toString()
                .replace(/[\[\]']+/g, '');
            }
          } else if (key === 'non-field-error') {
            if (error_field) {
              scope[error_field] = true;
            } else {
              scope.error = true;
            }
            scope.errorArray = [];
            if (reason.error['non-field-error'].length > 0) {
              for (let j = 0; j < reason.error['non-field-error'].length; j++) {
                scope.errorArray.push(
                  reason.error['non-field-error'][j].message
                );
              }
            }
            scope.errorMsg = scope.errorArray.toString();
          } else if (key === 'contact_person') {
            scope.error = true;
            if (
              reason.error[key].message != undefined &&
              reason.error[key].message.split("'")[1] != undefined
            ) {
              scope.errorMsg = reason.error[key].message.split("'")[1];
            } else {
              scope.errorMsg = reason.error[key].message;
            }
          } else if (key === 'non_field_error') {
            if (error_field) {
              scope[error_field] = true;
            } else {
              scope.error = true;
            }
            scope.errorArray = [];
            if (reason.error['non-field-error'].length > 0) {
              for (
                let l = 0;
                l < reason.error['non_field_error-field-error'].length;
                l++
              ) {
                scope.errorArray.push(
                  reason.error['non-non_field_error-error'][l].message
                );
              }
            }
            scope.errorMsg = scope.errorArray.toString();
          } else if (key === 'non_field_errors') {
            if (error_field) {
              scope[error_field] = true;
            } else {
              scope.error = true;
            }
            scope.errorArray = [];
            if (reason.error['non_field_errors'].length > 0) {
              for (
                let k = 0;
                k < reason.error['non_field_errors'].length;
                k++
              ) {
                if (reason.error[key][k].message.split("[u'")[1] != undefined) {
                  scope.errorArray.push(
                    reason.error['non_field_errors'][k].message
                      .split("[u'")[1]
                      .split("']")[0]
                  );
                } else {
                  scope.errorArray.push(
                    reason.error['non_field_errors'][k].message
                  );
                }
              }
            }
            scope.errorMsg = scope.errorArray.toString();
          } else if (key === 'user') {
            if (error_field) {
              scope[error_field] = true;
            } else {
              scope.error = true;
            }
            scope.errorArray = [];
            if (value instanceof Array) {
              for (let q = 0; q < value.length; q++) {
                scope.errorArray.push(value[q].code);
              }
            } else {
              if(value?.message) return scope.errorMsg = value.message;
              value.forEach((valueVal: any, valKey: any) => {
                if (valueVal instanceof Array) {
                  for (let x = 0; x < valueVal.length; x++) {
                    scope.errorArray.push(
                      this.translate.instant(valKey + '_' + valueVal[x].code)
                    );
                  }
                }
              });
              scope.errorMsg = scope.errorArray.join();
            }
          } else if (key === 'threshold_limits') {
            if (error_field) {
              scope[error_field] = true;
            } else {
              scope.error = true;
            }
            scope.errorArray = [];
            if (value instanceof Array) {
              for (let r = 0; r < value.length; r++) {
                if (
                  Object.keys(value[r]).length > 0 &&
                  value[r].redemption_threshold[0] !== undefined &&
                  value[r].redemption_threshold[0].code
                ) {
                  scope.errorArray.push(value[r].redemption_threshold[0].code);
                }
                if (
                  Object.keys(value[r]).length > 0 &&
                  value[r].loyalty_account[0] !== undefined &&
                  value[r].loyalty_account[0].code
                ) {
                  scope.errorArray.push(value[r].loyalty_account[0].code);
                }
              }
            }
            scope.errorMsg =
              this.translate.instant(key) +
              ' ' +
              this.translate.instant(scope.errorArray.join());
          } else if (key === 'email') {
            if (error_field) {
              scope[error_field] = true;
            } else {
              scope.error = true;
            }
            scope.errorMsg = this.translate.instant(value.code);
          } else if (key === 'internal_cost' || key === 'selling_price') {
            // window.scrollTo(0, 0);
            scope.error = true;
            if (reason.error[key].length > 0) {
              for (let price = 0; price < reason.error[key].length; price++) {
                scope.costArray.push(
                  this.translate.instant(key) +
                    ' : ' +
                    reason.error[key][price].message
                );
              }
            }
            scope.errorMsg = scope.costArray.toString();
          } else if (key === 'description') {
            if (error_field) {
              scope[error_field] = true;
            } else {
              scope.error = true;
            }
            if (value.length > 0) {
              let text = pagename + '_' + key + '_' + value[0].code;
              scope.errorMsg = this.translate.instant(text);
            } else {
              scope.errorMsg = this.translate.instant(value.code);
            }
          } else if (value.code === 'required') {
            let memberIndex = scope.enabledMemberAttributes.findIndex(
              (val: any) => {
                return val.name == key;
              }
            );
            if (error_field) {
              scope[error_field] = true;
            } else {
              scope.error = true;
            }
            if (memberIndex > 0) {
              scope.errorMsg =
                scope.enabledMemberAttributes[memberIndex].visible_name +
                ' ' +
                this.translate.instant('NO_EMPTY');
            } else {
              scope.errorMsg =
                this.translate.instant(key) +
                ' ' +
                this.translate.instant('NO_EMPTY');
            }
          } else {
            if (key !== 'scope') {
              if (Array.isArray(value)) {
                code = pagename + '_' + key + '_' + value[0].code;
              } else {
                code = pagename + '_' + key + '_' + value.code;
              }

              let dummyKey = '';
              if (
                formname === 'main' &&
                (key === 'end_date' || key === 'start_date')
              ) {
                dummyKey = key;
                key = 'daterange';
              }
              if (
                formname === 'productform' &&
                (key === 'valid_end' || key === 'valid_start')
              ) {
                dummyKey = key;
                key = 'daterange';
              }
              if (
                scope[formname] != undefined &&
                scope[formname][key] != undefined
              ) {
                if (
                  key === 'daterange' &&
                  (dummyKey === 'valid_start' ||
                    dummyKey === 'valid_end' ||
                    dummyKey === 'end_date' ||
                    dummyKey === 'start_date')
                ) {
                  scope[formname][key].api_error = true;
                  scope[formname][key][dummyKey] = code;
                  if (Array.isArray(value)) {
                    scope.errorMsg = value[0].message;
                  } else {
                    scope.errorMsg = value.message;
                  }
                } else {
                  scope[formname][key].api_error = true;
                  scope[formname][key].code = code;
                  //Some times value is coming as object and sometimes as array. so Adding condition to differentiate
                  if (Array.isArray(value)) {
                    scope.error = true;
                    scope[formname][key].errormsg = value[0].message;
                    scope.errorMsg = value[0].message;
                  } else if (
                    value != null &&
                    typeof value === 'object' &&
                    value[0][0] != undefined
                  ) {
                    scope.error = true;
                    // key = $filter('uppercase')(key.replace('_', ' '));
                    key = String(key.replace('_', ' ')).toUpperCase();
                    scope.errorMsg = key + ' : ' + value[0][0].message;
                  } else {
                    scope[formname][key].errormsg = value.message;
                    scope.errorMsg = value.message;
                  }
                }
              } else {
                if (error_field) {
                  scope[error_field] = true;
                } else {
                  scope.error = true;
                }
                let messageArray = [];
                if (!Array.isArray(reason.error)) {
                  messageArray.push(reason.error[key]);
                } else {
                  for (let z = 0; z < reason.error[key].length; z++) {
                    if (
                      reason.error[key][z].message.split("[u'")[1] != undefined
                    ) {
                      messageArray.push(
                        reason.error[key][z].message
                          .split("[u'")[1]
                          .split("']")[0]
                      );
                    } else {
                      messageArray.push(reason.error[key][z].message);
                    }
                  }
                }
                let messagelistArray = [];
                if (messageArray[0]?.length > 0) {
                  for (let n = 0; n < messageArray[0].length; n++) {
                    if (messageArray[0][n].message != undefined) {
                      if (messageArray[0][n].message.split("[u'")[1]) {
                        messagelistArray.push(
                          messageArray[0][n].message
                            .split("[u'")[1]
                            .split("']")[0]
                        );
                      } else {
                        messagelistArray.push(messageArray[0][n].message);
                      }
                    }
                  }
                  scope.errorMsg = messagelistArray.join();
                } else {
                  scope.errorMsg = messageArray.join();
                }

                // window.scrollTo(0, 0);
              }
            }
            //Some times value is coming as object and sometimes as array. so Adding condition to differentiate
          }
        });
      }
    } else if (
      reason != undefined &&
      reason !== null &&
      reason.error != undefined
    ) {
      if (error_field) {
        scope[error_field] = true;
      } else {
        scope.error = true;
      }
      if (reason.type != undefined && reason.type === 'ERROR') {
        scope.errorMsg = reason.message;
      } else {
        scope.errorMsg = reason;
      }
    } else {
      if (error_field) {
        scope[error_field] = true;
      } else {
        scope.error = true;
      }
      scope.errorMsg = reason;
      // window.scrollTo(0, 0);
    }
    if (scope && scope.errorMsg && scope.errorMsg[0] === '[') {
      scope.errorMsg = scope.errorMsg.substr(1);
    }
    if (
      scope &&
      scope.errorMsg &&
      scope.errorMsg[scope.errorMsg.length - 1] == ']'
    ) {
      scope.errorMsg = scope.errorMsg.substr(0, scope.errorMsg.length - 1);
    }
    if (scope && scope.errorMsg && scope.errorMsg[0] == "'") {
      scope.errorMsg = scope.errorMsg.substr(1);
    }
    if (
      scope &&
      scope.errorMsg &&
      scope.errorMsg[scope.errorMsg.length - 1] == "'"
    ) {
      scope.errorMsg = scope.errorMsg.substr(0, scope.errorMsg.length - 1);
    }
    return scope.errorMsg;
  }
}
