<button
  class="icon-button {{type}}"
  [attr.tabindex]="disabled ? '-1' : '0'"
  role="button"
  (keyup.enter)="onClick()"
  (click)="onClick()"
  [ngStyle]="{
    height: size + 'px',
    width: size + 'px'
  }"
  [title]="title"
  [disabled]="disabled"
  [attr.aria-label]="title"
  [attr.tabindex]="disabled ? '-1' : '0'"
>
  <img
    [src]="buttonUrl"
    alt="icon icon"
    [ngStyle]="{
      height: iconHeight + 'px',
      width: iconWidth + 'px'
    }"
  />
</button>
