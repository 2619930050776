<div class="image-upload-container" [ngClass]="{'image-upload-container__rounded':rounded,'image-upload-container__readOnly':readOnly}" [ngStyle]="{'background-color': backgroundColor && !uplodedImageUrl ? backgroundColor : 'none'}" (click)="fileInput.click()">
  <div class="image-upload">
    <div
      class="image-upload__item"
      [ngStyle]="{ width: width + widthUnit, height: height + heightUnit ,'background-image': 'url(' + uplodedImageUrl + ')' }"
    >
      <label  class="image-upload__label">
        <input
        type="file"
        #fileInput
        accept="image/*"
        (change)="onClick($event)"
        hidden
      />
        <img [src]="uplodedImageUrl ? '/assets/images/icons/camera_white@2x.png' : '/assets/images/icons/camera.png'"
          class="image-preview" alt="Desktop Preview" 
          [ngClass]="{'image-upload__item__uploded':uplodedImageUrl,
            'image-upload__item__uploded--rounded':uplodedImageUrl && rounded }"
        />
    
        <span class="image-upload__label__device-name" *ngIf="deviceTypeName && !uplodedImageUrl">{{deviceTypeName}}</span>
      </label>

    </div>
</div>
</div>
