<div
  class="{{ fieldId }} splitted-text-select-field"
  [ngStyle]="{ width: width }"
  #inputField
>
  <div
    class="{{ fieldId }} splitted-text-select-field__field"
    [ngClass]="{
      'splitted-text-select-field__field--disabled': disabled,
      'splitted-text-select-field__field--readonly': readOnly
    }"
    [id]="fieldId"
  >
    <div
      class="{{ fieldId }} splitted-text-select-field__field-button"
      [ngClass]="{
        'splitted-text-select-field__field-button--opened': showDropdown,
        'splitted-text-select-field__field-button--remove-cursor':
          disableSelectDropDown
      }"
    >
      <div
        class="{{ fieldId }} splitted-text-select-field__field-button__label"
        [ngClass]="{
          'splitted-text-select-field__field-button__label--required': required
        }"
        *ngIf="fieldTitle && fieldTitle.length > 0"
      >
        {{ fieldTitle }}:
      </div>
      <input
        type="text"
        class="splitted-text-select-field__field-button__input"
        [placeholder]="showInputPlaceholder ? inputPlaceholder : ''"
        (keyup)="emitTextChange($event)"
        [value]="formattedTextValue(this.textValue)"
        [title]="formattedTextValue(this.textValue) ?? inputPlaceholder"
        (keypress)="validateKeyboardEvent($event)"
      />
      <div
        class="{{ fieldId }} splitted-text-select-field__field-button__dropdown"
        [ngClass]="{
          'splitted-text-select-field__field-button__dropdown--disabled':
            disableSelectDropDown
        }"
        (click)="toggleDropdown()"
        *ngIf="!hideSelectField"
      >
        <button
          class="{{ fieldId }} splitted-text-select-field__field-button__text"
          [ngClass]="{
            'splitted-text-select-field__field-button__text--placeholder':
              displayValue == null
          }"
          [title]="displayValue ?? selectPlaceholder"
        >
          {{ displayValue ?? selectPlaceholder }}
        </button>
      </div>
    </div>
    <div
      class="{{ fieldId }} splitted-text-select-field__field-dropdown"
      [ngClass]="{
        'splitted-text-select-field__field-dropdown--open': showDropdown
      }"
      #selectMenu
    >
      <ul
        class="{{ fieldId }} splitted-text-select-field__field-dropdown__list"
        role="listbox"
        #optionsSection
      >
        <li
          class="{{
            fieldId
          }} splitted-text-select-field__field-dropdown__list-item {{
            keyToClass(option.key)
          }}"
          *ngFor="
            let option of options;
            trackBy: trackOptions;
            let $index = index
          "
          role="option"
          [id]="fieldId + '-' + $index"
          [ngClass]="{
            'splitted-text-select-field__field-dropdown__list-item--focused':
              $index === focusedIndex
          }"
          (click)="selectOption(option.key)"
        >
          <span
            class="{{
              fieldId
            }} splitted-text-select-field__field-dropdown__list-item__text {{
              keyToClass(option.key)
            }}"
            [title]="option.value"
          >
            {{ option.value }}
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div class="splitted-text-select-field__error" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>
