import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gravty-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.less'],
})
export class InfoTooltipComponent implements OnInit {
  @Input() iconUrl: string =
    '/assets/img/Info_Icon.png';
  @Input() iconSize: number = 20;
  @Input() tooltipWidth: number = 306;
  @Input() tooltipHeight: number = 150;
  @Input() showLoader: boolean = false;
  @Input() info: InfoTooltipPayload[][] = [
    [
      { label: 'Created by', value: 'sample@gmail.com' },
      { label: 'Created by', value: 'sample@gmail.com' },
    ],
    [
      { label: 'Created by', value: 'sample@gmail.com' },
      { label: 'Created by', value: 'sample@gmail.com' },
    ],
  ];
  @Output() open: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Input() alignToolTip: 'left' | 'center' | 'right' = 'center';
  @Input() enableCustomDesign: boolean = false;
  @Input() disabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  onOpen() {
    this.open.emit();
  }
  onClose() {
    this.close.emit();
  }
}

interface InfoTooltipPayload {
  label: string;
  value: string;
}
