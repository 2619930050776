import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[gravtyToolTip]',
})
export class ToolTipDirective implements AfterViewInit, OnDestroy {
  @Input() margin: { top?: number; right?: number } = {};
  @Input() showOnHover: boolean = true;
  @Input() closeOnMouseLeave: boolean = true;
  @Input() position: 'fixed' | 'absolute' = 'fixed';
  @Input() display: 'block' | 'flex' | 'none' = 'block';
  @Input() relativeTo: {
    vertical: 'parent' | 'body';
    horizontal: 'parent' | 'body';
  } = {
    vertical: 'parent',
    horizontal: 'body',
  };

  @Output() open: EventEmitter<void> = new EventEmitter<void>();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('mouseover', ['$event'])
  showToolTip() {
    this.positionTooltip();
    if (this.showOnHover) {
      this.renderer.setStyle(this.tooltipComponent, 'display', this.display);
      this.open.emit();
    }
  }
  @HostListener('mouseleave', ['$event'])
  hideToolTip() {
    this.positionTooltip();
    if (this.closeOnMouseLeave) {
      this.renderer.setStyle(this.tooltipComponent, 'display', 'none');
      this.close.emit();
    }
  }

  @HostListener('click', ['$event'])
  showToolTipOnClick(event: Event) {
    this.positionTooltip();
    const currentDisplay = this.tooltipComponent?.style.display ?? 'none';
    if (currentDisplay == '' || currentDisplay == 'none') {
      this.renderer.setStyle(this.tooltipComponent, 'display', this.display);
      this.open.emit();
    } else {
      this.renderer.setStyle(this.tooltipComponent, 'display', 'none');
      this.close.emit();
    }
    event?.preventDefault();
    event?.stopImmediatePropagation();
  }

  @HostListener('document:click', ['$event'])
  hideToolTipOnClick(event: Event) {
    this.positionTooltip();
    const currentDisplay = this.tooltipComponent?.style.display ?? 'none';
    if (currentDisplay != 'none') {
      this.renderer.setStyle(this.tooltipComponent, 'display', 'none');
      this.close.emit();
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  tooltipComponent?: HTMLElement;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
  ngAfterViewInit() {
    this.tooltipComponent = this.elementRef?.nativeElement?.lastChild;
    if (this.tooltipComponent) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'cursor',
        'pointer'
      );
      this.hideToolTip();
      this.renderer.listen(this.elementRef.nativeElement, 'keyup', (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          this.showToolTip();
        }
      });
      this.renderer.listen(this.elementRef.nativeElement, 'blur', () => {
        this.hideToolTip();
      });
    }
    this.positionTooltip();
  }

  positionTooltip() {
    if (this.tooltipComponent) {
      const parentPosition: DOMRect =
        this.elementRef.nativeElement.getBoundingClientRect();

      this.renderer.setStyle(this.tooltipComponent, 'position', this.position);
      if (this.relativeTo.horizontal == 'body') {
        this.renderer.setStyle(
          this.tooltipComponent,
          'right',
          (this.margin.right ?? 0) + 'px'
        );
      } else {
        this.renderer.setStyle(
          this.tooltipComponent,
          'left',
          parentPosition.left + (this.margin.right ?? 0) + 'px'
        );
      }

      if (this.relativeTo.vertical == 'body') {
        this.renderer.setStyle(
          this.tooltipComponent,
          'top',
          (this.margin.top ?? 0) + 'px'
        );
      } else {
        this.renderer.setStyle(
          this.tooltipComponent,
          'top',
          parentPosition.bottom + (this.margin.top ?? 0) + 'px'
        );
      }
    }
  }
  ngOnDestroy(): void {}
}
