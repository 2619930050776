<div
  class="custom-checkbox__icon"
  [attr.tabindex]="disabled ? '-1' : '0'"
  role="checkbox"
  (keyup.enter)="toggleCheckBox()"
  (click)="toggleCheckBox()"
  [style]="{
      width: width + widthUnit,
      height: height + heightUnit,
    }"
  [ngClass]="{
      shaded: isShadow,
      checked: isChecked && !rounded,
      disabled: disabled,
      readOnly: readOnly,
      'apply-border': rounded,
    }"
>
  <input
    type="checkbox"
    aria-label="checkbox"
    class="custom-checkbox__icon--input"
    [style]="{
      width: width + widthUnit,
      height: height + heightUnit
    }"
    [ngModel]="isChecked"
  />
  <span
    class="custom-checkbox__icon--checkmark"
    [ngClass]="{
      'custom-checkbox__icon--checkmark--checked': isChecked && rounded
    }"
    [style]="{ width: width + widthUnit, height: height + heightUnit }"
  >
    <img
      *ngIf="isChecked && !rounded"
      src="../../../../../assets/images/icons/checkmark@3x.png"
      alt="checkmark"
    />
  </span>
</div>
