import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) {}
  uploadFile(inputElement: HTMLInputElement) {
    return new Observable((observer) => {
      if (inputElement.files && inputElement.files.length > 0) {
        let files = new DataTransfer();
        files.items.add(
          new File(
            [inputElement.files[0]],
            inputElement.files[0].name
              .split(' ')
              .join('')
              .replace(/[^a-zA-Z0-9_.]/g, ''),
            {
              type: inputElement.files[0].type,
              lastModified: inputElement.files[0].lastModified,
            }
          )
        );
        inputElement.files = files.files;
        let reader = new FileReader();
        reader.onload = (data: any) => {
          const file = {
            lastModified: inputElement.files![0].lastModified,
            lastModifiedDate: inputElement.files![0].lastModified,
            name: inputElement
              .files![0].name.split(' ')
              .join('')
              .replace(/[^a-zA-Z0-9_.]/g, ''),
            size: inputElement.files![0].size,
            type: inputElement.files![0].type,
            data: data.target.result,
          };
          observer.next(file);
        };
        reader.onerror = (err) => {
          observer.error(err);
        };
        if (inputElement.files && inputElement.files[0] instanceof Blob) {
          reader.readAsDataURL(inputElement.files[0]);
        }
      } else {
        observer.error('File Not Found');
      }
    });
  }

  uploadDocumentFile(changeEvent?: any, ref?: any){
    if(changeEvent.target!==undefined && changeEvent.target.files[0]!==undefined){
        let files = new DataTransfer();
        files.items.add(new File([changeEvent.target.files[0]], changeEvent.target.files[0].name.split(" ").join("").replace( /[^a-zA-Z0-9_.]/g, ''), {
            type : changeEvent.target.files[0].type,
            lastModified : changeEvent.target.files[0].lastModified
        }));
        changeEvent.target.files = files.files;
    }
    var reader = new FileReader();
    reader.onload =  (loadEvent : any) => {
        ref.file = {
            lastModified: changeEvent.target.files[0].lastModified,
            lastModifiedDate: changeEvent.target.files[0].lastModifiedDate,
            name: changeEvent.target.files[0].name.split(" ").join("").replace( /[^a-zA-Z0-9_.]/g, ''),
            size: changeEvent.target.files[0].size,
            type: changeEvent.target.files[0].type,
            data: loadEvent.target.result
        };
        if(ref.ele) {
            ref.uploadImage(ref, ref.ele);
        }

        if(ref.ele1) {
            ref.uploadImage(ref, ref.ele1);
        }
        if(ref.uploadDocument){
            ref.uploadDocument(ref);
        }
        if(ref.uploadImage && (ref.ele == null || ref.ele == undefined || ref.ele == '')){
            ref.uploadImage(ref);
        }
    };
    if(changeEvent.target.files[0] instanceof Blob){
        reader.readAsDataURL(changeEvent.target.files[0]);
    }
}

  upLoadImage(request: any, host: any) {
    let requestUrl = host.replace('v1','v2') + 'sign-s3/';
    return this.http.post(requestUrl, request);
  }

  uploadDocument(sponsorId: string, request : any, host: any) {
    const requestUrl = host.replace('v1','v2') + `sponsors/${sponsorId}/documents/`;
    return this.http.post(requestUrl, request);
  }
}
