import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { debounceTime, fromEvent, map } from 'rxjs';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.less',
})
export class SearchInputComponent implements OnInit {
  @Input() searchValue: string = '';
  @Input() delay: number = 300;
  @Output() searchValueChange = new EventEmitter();
  getGenericLabels: any;
  constructor(public elementRef: ElementRef) {
    const eventStream = fromEvent(elementRef.nativeElement, 'keyup')
      .pipe(
        map(() => this.searchValue),
        debounceTime(this.delay)
      )
      .subscribe((input) => {
        this.searchValueChange.emit(input.trim());
      });
  }

  ngOnInit(): void {
    this.getGenericLabels = JSON.parse(localStorage.getItem('genericLabels') || '{}');
  }
}
