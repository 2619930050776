import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.less',
})
export class PaginationComponent implements OnInit {
  @Input() width: number = 940;
  @Input() widthUnit: 'px' | '%' | 'rem' | 'em' = 'px';
  @Input() heightUnit: 'px' | '%' | 'rem' | 'em' = 'px';
  @Input() height: number = 17;
  @Input() previousUrl: string = '';
  @Input() nextUrl: string = '';
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();
  getGenericLabels: any;

  constructor() {}

  ngOnInit(): void {
    this.getGenericLabels = JSON.parse(localStorage.getItem('genericLabels') || '{}');
  }
  onClick(url: string) {
    this.navigate.emit(url);
  }
}
