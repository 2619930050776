import dayjs from 'dayjs';
import {
  Filter,
  FilterDataType,
  SuperFilterConfig,
} from '../interfaces/super-filter-config.interface';

class SuperFilterUtil {
  public static generateFilterObject(
    config: SuperFilterConfig,
    dateTimeformat: boolean = false
  ) {
    let dateFormat: string = '';
    let defaultFilters: any = {};
    dateFormat = dateTimeformat ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD';
    defaultFilters[config.sort?.filterKey ?? 'ordering'] =
      config.sort?.value ?? config.sort?.default;

    return config.filters.reduce(
      (acc: any, curr) => {
        switch (curr.type) {
          case FilterDataType.DATE_RANGE:
            if (curr.value?.startDate) {
              acc['date_from'] = dayjs(
                curr.value.startDate,
                'YYYY-MM-DDTHH:mm:ss'
              ).format(dateFormat);
            }
            if (curr.value?.endDate) {
              acc['date_to'] = dayjs(
                curr.value.endDate,
                'YYYY-MM-DDTHH:mm:ss'
              ).format(dateFormat);
            }
            break;
          case FilterDataType.DATE:
            if (curr.value?.startDate) {
              acc[curr.filterKey] = dayjs(
                curr.value.startDate,
                'YYYY-MM-DDTHH:mm:ss'
              ).format('YYYY-MM-DD');
            }
            break;
          default:
            acc[curr.filterKey] = curr.value ?? curr.default;
        }

        return acc;
      },
      { ...defaultFilters }
    );
  }

  public static resetFilters(config: SuperFilterConfig): SuperFilterConfig {
    const filters: Filter[] = [];
    config.filters.forEach((filter) => {
      let tempFilter: Filter = {
        ...filter,
      };

      // To Handle Multi-select, ALL selection
      if (
        tempFilter.type == FilterDataType.MULTI_SELECT &&
        tempFilter.multiSelectConfig?.selectAll
      ) {
        tempFilter.default = filter.options?.map((option) => option.key);
      }
      if (tempFilter.type === FilterDataType.FACETS_ACTION) {
        tempFilter.options?.forEach((element: any) => {
          element.selected = false;
        });
      }
      if (!tempFilter.showDefault) {
        filters.push({ ...tempFilter, value: undefined });
      }
      if (tempFilter.showDefault) {
        filters.push({ ...tempFilter, value: tempFilter.showDefault });
      }
    });
    if (config.sort) {
      config.sort.value = undefined;
    }
    config.filters = [...filters];
    return structuredClone(config);
  }

  public static isFilterApplied(config: SuperFilterConfig): boolean {
    const isFilterActive = config.filters.some(
      (filter) =>
        filter.value != undefined &&
        !(Array.isArray(filter.value)
          ? (filter.default as [])?.every((val: any) =>
              filter.value.includes(val)
            ) &&
            (filter.value as [])?.every((val: any) =>
              filter.default.includes(val)
            )
          : JSON.stringify(filter.value) == JSON.stringify(filter.default))
    );

    const isSortApplied =
      config.sort?.value != undefined &&
      config.sort?.default != config.sort?.value;
    return isFilterActive || isSortApplied;
  }
}

export { SuperFilterUtil };
