/**
 *
 *  AlertDialogComponent
 *  @description Dialog component for displaying alert messages to the user.
 *  @Invison https://lji.invisionapp.com/console/GRAVTY---Generic-Merchant-Portal-cm0pm2flg11ug01a4a44w327q/cm15dqjbf05c4018v8vjs37rr/inspect
 *  @example    
 *  const config: AlertDialogPayload = {
      icon: '',
      title: 'Warning',
      body: [`Text.`,`Are you sure you want to proceed?`],
      actions: [
        {
          displayText: 'Cancel',
          key: 'cancel',
          type: 'default',
          width: 155,
          height: 48,
        },
        {
          displayText: 'Yes',
          key: 'ok',
          type: 'primary',
          width: 155,
          height: 48,
        },
      ],
      type: ALERT_DIALOG_TYPE.DEFAULT,
    };
  this.libraryService.openAlertDialog(config).afterClosed().subscribe({
      next:(res: any) => {
        if(res === 'ok') {
          this.libraryService.openAlertDialog(config).close();
        }
      }
    });
 */

import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogPayload } from '../../interfaces/alert-dialog-payload.interface';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrl: './alert-dialog.component.less'
})
export class AlertDialogComponent {
  data: AlertDialogPayload;

  constructor(
    @Inject(MAT_DIALOG_DATA) payload: AlertDialogPayload,
    private dialogRef: MatDialogRef<AlertDialogComponent>
  ) {
    this.data = payload;
  }

  closeDialog(key: string) {
    this.dialogRef.close(key);
  }
}
