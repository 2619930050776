import { Component, EventEmitter, Input, Output } from '@angular/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DatePickerPayload } from '../../interfaces/date-picker-payload.interface';
import { DateRangePicker } from '../../interfaces/date-range-picker.interface';
import { DateTimeHelper } from '../../../../shared/helpers/datetime.helper';
import { DateRangePickerComponent } from '../date-range-picker/date-range-picker.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { SharedService } from '../../../../shared/shared.service';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

@Component({
  selector: 'app-date-input-field',
  templateUrl: './date-input-field.component.html',
  styleUrl: './date-input-field.component.less',
})
export class DateInputFieldComponent {
  calendarRef: any;
  @Input() systemFormat: string = 'YYYY-MM-DDTHH:mm:ss';
  @Input() value: { startDate?: string; endDate?: string } = {};
  @Input() fieldTitle?: string;
  @Input() placeholder: string = '';
  @Input() defaultValue: string = '';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() config?: Partial<DatePickerPayload | DateRangePicker>;
  @Input() isRangePicker: boolean = false;
  @Input() width: number = 100;
  @Input() widthUnit: 'px' | '%' | 'rem' | 'em' = '%';
  @Input() showError: boolean = true;
  @Input() highlightOnFocus: string = '';
  @Input() autofocusField: boolean = false;
  @Input() defaultFocus: boolean = false;
  @Output() valueChange = new EventEmitter<{
    startDate?: string;
    endDate?: string;
  }>();

  @Input() errorMessage: string | undefined;
  @Input() toolTipMessage: string | undefined;
  @Input() includeErrorMsgPaddingFlag: boolean = true;
  @Input() tooltipWidth: number | undefined;
  @Input() tooltipHeight: number | undefined;
  @Input() readOnly: boolean = false;
  constructor(
    private matDialog: MatDialog,
    private sharedService: SharedService
  ) {}

  get currentTimeZone() {
    return this.sharedService.programTimeZone;
  }
  get currentDateFormat() {
    return this.config?.showTimeFields
      ? DateTimeHelper.dateTimeFormat
      : DateTimeHelper.dateFormat;
  }

  ngOnInit(): void {
    dayjs.tz.setDefault(this.currentTimeZone);
  }

  openPicker() {
    if (this.calendarRef) {
      this.calendarRef.close();
    }
    if (this.isRangePicker) {
      const payload = this.dateRangePickerConfig;
      this.calendarRef = this.openDateRangePicker(payload);
    } else {
      const payload = this.datePickerConfig;
      this.calendarRef = this.openDatePicker(payload);
    }
  }

  ngAfterViewInit() {
  }

  get datePickerConfig(): DatePickerPayload {
    let startDate;
    if (this.value?.startDate) {
      if (this.systemFormat.includes('Z')) {
        startDate = dayjs(this.value.startDate, this.systemFormat);
      } else {
        startDate = dayjs.tz(
          this.value.startDate,
          this.systemFormat,
          this.currentTimeZone
        );
      }
    }
    return {
      outputFormat: 'YYYY-MM-DD',
      ...this.config,
      selectedDate: startDate,
      onSave: (selectedDate: dayjs.Dayjs, formattedDate: string) => {
        this.value = {
          startDate: selectedDate.tz().format(this.systemFormat),
          endDate: undefined,
        };
        if (this.config?.onSave) {
          (this.config.onSave as any)(selectedDate, formattedDate);
        }
        this.valueChange.emit({ ...this.value });
      },
    };
  }
  get dateRangePickerConfig(): DateRangePicker {
    let startDate;
    if (this.value?.startDate && this.value?.startDate.length > 0) {
      if (this.systemFormat.includes('Z')) {
        startDate = dayjs(this.value.startDate, this.systemFormat);
      } else {
        startDate = dayjs.tz(
          this.value.startDate,
          this.systemFormat,
          this.currentTimeZone
        );
      }
    }
    let endDate;
    if (this.value?.endDate && this.value?.endDate.length > 0) {
      if (this.systemFormat.includes('Z')) {
        endDate = dayjs(this.value.endDate, this.systemFormat);
      } else {
        endDate = dayjs.tz(
          this.value.endDate,
          this.systemFormat,
          this.currentTimeZone
        );
      }
    }
    let headTitle = this.config?.title;
    if (this.fieldTitle === 'Validity Timeframe') {
      headTitle = 'Select ' + this.fieldTitle;
    }
    if (
      this.fieldTitle === 'Product Validity' ||
      this.fieldTitle === 'Reward & Award Validity' ||
      this.fieldTitle === 'Award Validity'
    ) {
      headTitle = 'Select Product Validity';
    }
    return {
      outputFormat: 'YYYY-MM-DD',
      allowSingleDateSelection: false,
      ...this.config,
      selectedStartDate: startDate,
      selectedEndDate: endDate,
      title: headTitle,
      onSave: (
        startDate: dayjs.Dayjs,
        endDate: dayjs.Dayjs | undefined,
        formattedDate: (string | undefined)[]
      ) => {
        this.value = {
          startDate: startDate.tz().format(this.systemFormat),
          endDate: undefined,
        };
        if (endDate) {
          this.value.endDate = endDate.tz().format(this.systemFormat);
        }
        this.valueChange.emit({ ...this.value });
      },
    };
  }
  get display(): string {
    let displayValue = this.defaultValue;
    try {
      if (this.value?.startDate && this.value?.startDate.length > 0) {
        if (this.systemFormat.includes('Z')) {
          displayValue = dayjs(this.value.startDate, this.systemFormat)
            .tz()
            .format(this.currentDateFormat ?? 'YYYY-MM-DD');
        } else {
          displayValue = dayjs
            .tz(this.value.startDate, this.systemFormat, this.currentTimeZone)
            .tz()
            .format(this.currentDateFormat ?? 'YYYY-MM-DD');
        }
      }
      if (this.value?.endDate && this.value?.endDate.length > 0) {
        if (this.systemFormat.includes('Z')) {
          displayValue +=
            ' - ' +
            dayjs(this.value.endDate, this.systemFormat)
              .tz()
              .format(this.currentDateFormat ?? 'YYYY-MM-DD');
        } else {
          displayValue +=
            ' - ' +
            dayjs
              .tz(this.value.endDate, this.systemFormat, this.currentTimeZone)
              .tz()
              .format(this.currentDateFormat ?? 'YYYY-MM-DD');
        }
      }
    } catch (error) {
      displayValue = this.defaultValue;
    }
    return displayValue;
  }

  openDatePicker(payload: DatePickerPayload) {
    const backdropClasses = ['datepicker-dialog-backdrop', 'hidable-backdrop'];
    if (payload.hideBackdrop) {
      backdropClasses.push('datepicker-dialog-backdrop--hide');
    }
    return this.matDialog.open(DatePickerComponent, {
      backdropClass: backdropClasses,
      panelClass: ['datepicker-panel', 'hidable-panel'],
      width: '500px',
      data: payload,
      hasBackdrop: true,
      disableClose: true,
      ariaLabel: 'Date Picker',
    });
  }

  openDateRangePicker(payload: DateRangePicker) {
    const backdropClasses = ['datepicker-dialog-backdrop'];
    if (payload.hideBackdrop) {
      backdropClasses.push('datepicker-dialog-backdrop--hide');
    }
    const panelClasses = ['datepicker-panel'];
    if (payload.positionChange) {
      panelClasses.push('datepicker-panel--position-change');
    }
    return this.matDialog.open(DateRangePickerComponent, {
      backdropClass: backdropClasses,
      panelClass: panelClasses,
      width: '598px',
      data: payload,
      hasBackdrop: true,
      disableClose: true,
      ariaLabel: 'Date Range Picker',
    });
  }
}
