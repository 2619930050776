import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrl: './placeholder.component.less',
})
export class PlaceholderComponent {
  @Input() helpText: string = '';
  @Input() subhelpText: string = '';
  @Input() height: number = 800;
  @Input() heightUnit: string = 'px';
  @Input() width:number=800;
  @Input() widthUnit:string='px';
  @Input() gap: number = 0;
}
