import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrl: './multi-select-dropdown.component.less',
})
export class MultiSelectDropdownComponent {
  @Input() height: number = 46;
  @Input() heightUnit: 'px' | '%' | 'em' | 'rem' = 'px';
  @Input() placeholder: string = 'Select';
  @Input() disabled: boolean = false;
  @Input() value: any[] = [];
  @Input() noTextTransform: boolean = false;
  @Input() options: { key: any; value: string }[] = [
    {
      key: 1,
      value: '100',
    },
    {
      key: 2,
      value: '200',
    },
    {
      key: 3,
      value: '300',
    },
    {
      key: 4,
      value: '400',
    },
    {
      key: 5,
      value: '500',
    },
  ];
  @Input() width: number = 166;
  @Input() widthUnit: 'px' | '%' | 'em' | 'rem' = 'px';
  @Output() valueChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  showDropdown: boolean = false;
  @ViewChild('SelectDropdown') dropdownRef: ElementRef | undefined;
  @ViewChild('inputField') fieldRef: ElementRef | undefined;
  allFlag: boolean = false;
  @Input() allText: string = 'All';
  @Input() showAll: boolean = true;
  @Input() fieldTitle?: string;
  @Input() required: boolean = false;
  @Input() forModal: boolean = true;
  ngOnInit(): void {}

  checkAll(event: Event) {
    if (this.isAllChecked) {
      this.value = [];
    } else {
      this.value = this.options.map((option) => {
        return option.key;
      });
    }
    this.valueChange.emit(this.value);
    event.stopPropagation();
    event.preventDefault();
  }

  get isAllChecked(): boolean {
    return !(this.options ?? []).some(
      (option) => !this.value?.includes(option.key)
    );
  }

  onClick(event: Event, key: any) {
    if (
      this.value?.length == 1 &&
      (this.value[0] === null || this.value[0]?.length === 0)
    ) {
      this.value = [];
    }
    const index = this.value.findIndex((val) => val == key);
    if (index == -1) {
      this.value.push(key);
    } else {
      this.value.splice(index, 1);
    }
    this.valueChange.emit(this.value);
    event.preventDefault();
    event.stopPropagation();
  }
  openDropdown() {
    this.showDropdown = !this.showDropdown;
    this.positionDropdown();
  }

  positionDropdown() {
    if (this.dropdownRef && this.fieldRef && this.forModal) {
      const fieldRects = this.fieldRef.nativeElement.getClientRects()[0];
      this.dropdownRef.nativeElement.style.top = fieldRects.top + 'px';
      this.dropdownRef.nativeElement.style.left = fieldRects.left + 'px';
      this.dropdownRef.nativeElement.style.width = fieldRects.width + 'px';
      this.dropdownRef.nativeElement.style.right = fieldRects.right + 'px';
    }
  }

  ngAfterViewInit() {
    document.addEventListener(
      'scroll',
      (event) => {
        const target = event?.target as HTMLElement;
        if (
          target?.className == null ||
          !target?.className?.includes('custom-dropdown')
        ) {
          this.showDropdown = false;
        }
      },
      true
    );
  }

  get displayValue(): string {
    if (this.isAllChecked && this.showAll && this.options?.length > 0) {
      return this.allText;
    }
    try {
      return this.value
        .map((val) => this.getOptionByKey(val)?.value)
        .filter((val) => val != null && val.length > 0)
        .join(', ');
    } catch (error) {
      this.value = [this.value];
      return this.value
        .map((val) => this.getOptionByKey(val)?.value)
        .filter((val) => val != null && val.length > 0)
        .join(', ');
    }
  }

  getOptionByKey(key: any) {
    return (this.options ?? []).find((val) => val.key == key);
  }
}
