import { DatePickerPayload } from './date-picker-payload.interface';
import { DateRangePicker } from './date-range-picker.interface';

export type SuperFilterConfig = {
  filters: Filter[];
  sort: Filter;
};
export interface Filter {
  type: FilterDataType;
  default: any;
  showDefault?: any;
  value?: any;
  filterKey: string;
  displayName: string;
  options?: { key: any; value: any }[];
  placeholder?: string;
  dateConfig?: DateRangePicker | DatePickerPayload;
  multiSelectConfig?: {
    showAll?: boolean;
    allText?: string;
    selectAll?: boolean;
  };
}
export enum FilterDataType {
  DATE,
  DATE_RANGE,
  SELECT,
  MULTI_SELECT,
  TEXT,
  NUMBER,
  FACETS_ACTION,
}
