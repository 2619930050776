<div
  class="global-error"
  *ngIf="
    apiError && apiError.scope == 'global' && apiError.message;
    else fieldScope
  "
  (click)="closeError()"
  tabindex="0"
  role="status"
  (keyup.enter)="closeError()"
>
  <div class="global-error__close"></div>
  <div class="global-error__message">
    {{ apiError.message }}
  </div>
</div>

<ng-template #fieldScope>
  <div
    class="global-error"
    [ngClass]="{'mt-0' : marginZero}"
    *ngIf="apiError && errorMessage.length > 0"
    (click)="closeError()"
    tabindex="0"
    role="status"
    (keyup.enter)="closeError()"
  >
    <div class="global-error__close"></div>
    <div class="global-error__message">
      {{ errorMessage }}
    </div>
  </div>
</ng-template>
