import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-custom-select-dropdown',
  templateUrl: './custom-select-dropdown.component.html',
  styleUrl: './custom-select-dropdown.component.less',
})
export class CustomSelectDropdownComponent {
  @Input() placeholder: string = 'Select';
  @Input() disabled: boolean = false;
  @Input() value: any = '';
  @Input() options: {
    key: any;
    value: string | number;
  }[] = [
    {
      key: 1,
      value: 'Franchise',
    },
    {
      key: 2,
      value: 'Registered Business',
    },
    {
      key: 3,
      value: 'Sole Proprietor',
    },
    {
      key: 4,
      value: 'Sole Proprietor',
    },
  ];
  @Input() width: number = 166;
  @Input() widthUnit: 'px' | '%' | 'em' | 'rem' = 'px';
  @Input() height: number = 48;
  @Input() heightUnit: 'px' | '%' | 'em' | 'rem' = 'px';
  @Input() fieldTitle?: string;
  @Input() readOnly: boolean = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() forModal: boolean = true;
  @Input() required: boolean = false;
  @ViewChild('SelectDropdown') dropdownRef: ElementRef | undefined;
  @ViewChild('inputField') fieldRef: ElementRef | undefined;
  showDropdown: boolean = false;
  constructor() {}
  onSelect(key: any) {
    this.value = key;
    this.valueChange.emit(key);
    this.showDropdown = false;
  }
  openDropdown() {
    this.showDropdown = true;
    this.positionDropdown();
  }

  positionDropdown() {
    if (this.dropdownRef && this.fieldRef && this.forModal) {
      const fieldRects = this.fieldRef.nativeElement.getClientRects()[0];
      this.dropdownRef.nativeElement.style.top = fieldRects.top + 'px';
      this.dropdownRef.nativeElement.style.left = fieldRects.left + 'px';
      this.dropdownRef.nativeElement.style.width = fieldRects.width + 'px';
      this.dropdownRef.nativeElement.style.right = fieldRects.right + 'px';
    }
  }

  groupOptions(index: any, item: any) {
    return item.key;
  }
  ngAfterViewInit() {
    document.addEventListener(
      'scroll',
      (event) => {
        const target = event?.target as HTMLElement;
        if (!target?.className?.includes('custom-dropdown')) {
          this.showDropdown = false;
        }
      },
      true
    );
  }
  get displayValue(): string {
    if (this.options) {
      return (
        this.options
          ?.find((option) => option.key == this.value)
          ?.value.toString() ?? ''
      );
    }
    return '';
  }
}
