var R = {
    0: 8203,
    1: 8204,
    2: 8205,
    3: 8290,
    4: 8291,
    5: 8288,
    6: 65279,
    7: 8289,
    8: 119155,
    9: 119156,
    a: 119157,
    b: 119158,
    c: 119159,
    d: 119160,
    e: 119161,
    f: 119162
  },
  b = {
    0: 8203,
    1: 8204,
    2: 8205,
    3: 65279
  },
  V = new Array(4).fill(String.fromCodePoint(b[0])).join(""),
  J = "\0";
function _(e) {
  let t = JSON.stringify(e);
  return `${V}${Array.from(t).map(o => {
    let n = o.charCodeAt(0);
    if (n > 255) throw new Error(`Only ASCII edit info can be encoded. Error attempting to encode ${t} on character ${o} (${n})`);
    return Array.from(n.toString(4).padStart(4, "0")).map(r => String.fromCodePoint(b[r])).join("");
  }).join("")}`;
}
function D(e) {
  return !Number.isNaN(Number(e)) || /[a-z]/i.test(e) && !/\d+(?:[-:\/]\d+){2}(?:T\d+(?:[-:\/]\d+){1,2}(\.\d+)?Z?)?/.test(e) ? !1 : !!Date.parse(e);
}
function Q(e) {
  try {
    new URL(e, e.startsWith("/") ? "https://acme.com" : void 0);
  } catch (t) {
    return !1;
  }
  return !0;
}
function z(e, t, o = "auto") {
  return o === !0 || o === "auto" && (D(e) || Q(e)) ? e : `${e}${_(t)}`;
}
var Z = Object.fromEntries(Object.entries(b).map(e => e.reverse())),
  k = Object.fromEntries(Object.entries(R).map(e => e.reverse())),
  G = `${Object.values(R).map(e => `\\u{${e.toString(16)}}`).join("")}`,
  I = new RegExp(`[${G}]{4,}`, "gu");
function H(e) {
  let t = e.match(I);
  if (t) return K(t[0], !0)[0];
}
function K(e, t = !1) {
  let o = Array.from(e);
  if (o.length % 2 === 0) {
    if (o.length % 4 || !e.startsWith(V)) return X(o, t);
  } else throw new Error("Encoded data has invalid length");
  let n = [];
  for (let r = o.length * 0.25; r--;) {
    let s = o.slice(r * 4, r * 4 + 4).map(i => Z[i.codePointAt(0)]).join("");
    n.unshift(String.fromCharCode(parseInt(s, 4)));
  }
  if (t) {
    n.shift();
    let r = n.indexOf(J);
    return r === -1 && (r = n.length), [JSON.parse(n.slice(0, r).join(""))];
  }
  return n.join("").split(J).filter(Boolean).map(r => JSON.parse(r));
}
function X(e, t) {
  var o;
  let n = [];
  for (let c = e.length * 0.5; c--;) {
    let u = `${k[e[c * 2].codePointAt(0)]}${k[e[c * 2 + 1].codePointAt(0)]}`;
    n.unshift(String.fromCharCode(parseInt(u, 16)));
  }
  let r = [],
    s = [n.join("")],
    i = 10;
  for (; s.length;) {
    let c = s.shift();
    try {
      if (r.push(JSON.parse(c)), t) return r;
    } catch (u) {
      if (!i--) throw u;
      let f = +((o = u.message.match(/\sposition\s(\d+)$/)) == null ? void 0 : o[1]);
      if (!f) throw u;
      s.unshift(c.substring(0, f), c.substring(f));
    }
  }
  return r;
}
function Y(e) {
  var t;
  return {
    cleaned: e.replace(I, ""),
    encoded: ((t = e.match(I)) == null ? void 0 : t[0]) || ""
  };
}
function T(e, t) {
  return z(e, t);
}
function se(e) {
  return _(e);
}
function ce(e) {
  return H(e);
}
function ie(e) {
  return Y(e);
}
var q = Object.prototype.hasOwnProperty,
  ee = Object.prototype.toString,
  te = function (t, o, n) {
    if (ee.call(o) !== "[object Function]") throw new TypeError("iterator must be a function");
    var r = t.length;
    if (r === +r) for (var s = 0; s < r; s++) o.call(n, t[s], s, t);else for (var i in t) q.call(t, i) && o.call(n, t[i], i, t);
  },
  ne = te,
  y = a;
function a(e, t, o) {
  if (arguments.length === 3) return a.set(e, t, o);
  if (arguments.length === 2) return a.get(e, t);
  var n = a.bind(a, e);
  for (var r in a) a.hasOwnProperty(r) && (n[r] = a[r].bind(n, e));
  return n;
}
a.get = function (t, o) {
  for (var n = Array.isArray(o) ? o : a.parse(o), r = 0; r < n.length; ++r) {
    var s = n[r];
    if (!(typeof t == "object" && s in t)) throw new Error("Invalid reference token: " + s);
    t = t[s];
  }
  return t;
};
a.set = function (t, o, n) {
  var r = Array.isArray(o) ? o : a.parse(o),
    s = r[0];
  if (r.length === 0) throw Error("Can not set the root object");
  for (var i = 0; i < r.length - 1; ++i) {
    var c = r[i];
    typeof c != "string" && typeof c != "number" && (c = String(c)), !(c === "__proto__" || c === "constructor" || c === "prototype") && (c === "-" && Array.isArray(t) && (c = t.length), s = r[i + 1], c in t || (s.match(/^(\d+|-)$/) ? t[c] = [] : t[c] = {}), t = t[c]);
  }
  return s === "-" && Array.isArray(t) && (s = t.length), t[s] = n, this;
};
a.remove = function (e, t) {
  var o = Array.isArray(t) ? t : a.parse(t),
    n = o[o.length - 1];
  if (n === void 0) throw new Error('Invalid JSON pointer for remove: "' + t + '"');
  var r = a.get(e, o.slice(0, -1));
  if (Array.isArray(r)) {
    var s = +n;
    if (n === "" && isNaN(s)) throw new Error('Invalid array index: "' + n + '"');
    Array.prototype.splice.call(r, s, 1);
  } else delete r[n];
};
a.dict = function (t, o) {
  var n = {};
  return a.walk(t, function (r, s) {
    n[s] = r;
  }, o), n;
};
a.walk = function (t, o, n) {
  var r = [];
  n = n || function (s) {
    var i = Object.prototype.toString.call(s);
    return i === "[object Object]" || i === "[object Array]";
  }, function s(i) {
    ne(i, function (c, u) {
      r.push(String(u)), n(c) ? s(c) : o(c, a.compile(r)), r.pop();
    });
  }(t);
};
a.has = function (t, o) {
  try {
    a.get(t, o);
  } catch (n) {
    return !1;
  }
  return !0;
};
a.escape = function (t) {
  return t.toString().replace(/~/g, "~0").replace(/\//g, "~1");
};
a.unescape = function (t) {
  return t.replace(/~1/g, "/").replace(/~0/g, "~");
};
a.parse = function (t) {
  if (t === "") return [];
  if (t.charAt(0) !== "/") throw new Error("Invalid JSON pointer: " + t);
  return t.substring(1).split(/\//).map(a.unescape);
};
a.compile = function (t) {
  return t.length === 0 ? "" : "/" + t.map(a.escape).join("/");
};
const re = ({
    pointer: e,
    mappings: t,
    data: o,
    hiddenStrings: n
  }) => {
    const r = t[e];
    delete t[e];
    const s = F(o, e);
    for (const i of s) {
      t[i] = r;
      const c = y.get(o, i),
        u = T(c, n);
      y.set(o, i, u);
    }
  },
  F = (e, t = "") => {
    const o = [],
      n = y.get(e, t);
    if (n.content) for (let r = 0; r < n.content.length; r++) n.content[r].nodeType === "text" ? o.push(`${t}/content/${r}/value`) : o.push(...F(e, `${t}/content/${r}`));
    return o;
  },
  x = ({
    entityId: e,
    entityType: t,
    space: o,
    environment: n,
    field: r,
    locale: s,
    editorInterface: i,
    fieldType: c,
    targetOrigin: u,
    platform: f
  }) => {
    const d = {
      origin: "contentful.com",
      href: `${`${u || "https://app.contentful.com"}/spaces/${o}/environments/${n}`}/${t === "Entry" ? "entries" : "assets"}/${e}/?focusedField=${r}&focusedLocale=${s}&source=vercel-content-link`,
      contentful: {
        space: o,
        environment: n,
        field: r,
        locale: s,
        entity: e,
        entityType: t,
        editorInterface: i,
        fieldType: c
      }
    };
    return f === "vercel" && delete d.contentful, f === "contentful" && delete d.href, d;
  },
  W = e => ["builtin", "sidebar-builtin", "editor-builtin"].includes(e),
  U = e => oe.includes(e);
function j(e) {
  if (typeof structuredClone == "function") return structuredClone(e);
  try {
    return JSON.parse(JSON.stringify(e));
  } catch (t) {
    return console.warn("Failed to clone data:", e, t), e;
  }
}
const oe = ["singleLine", "tagEditor", "listInput", "checkbox", "richTextEditor", "multipleLine"];
function C(e, t, o, n, r, s, i) {
  const c = i ? t[i] : t;
  switch (e) {
    case "Symbol":
      {
        const u = T(c, o);
        y.set(n, r, u);
        break;
      }
    case "Text":
      {
        const u = T(c, o);
        y.set(n, r, u);
        break;
      }
    case "RichText":
      {
        re({
          pointer: "",
          mappings: s,
          data: c,
          hiddenStrings: o
        });
        break;
      }
    case "Array":
      {
        const u = c.map(f => typeof f == "string" ? T(f, o) : f);
        y.set(n, r, u);
        break;
      }
  }
}
const ae = (e, t, o) => {
    if (!e || !e.extensions || !e.extensions.contentSourceMaps) return console.error("GraphQL response does not contain Content Source Maps information.", e), e;
    const n = j(e),
      {
        spaces: r,
        environments: s,
        editorInterfaces: i,
        fields: c,
        locales: u,
        entries: f,
        assets: p,
        mappings: g,
        fieldTypes: v
      } = n.extensions.contentSourceMaps,
      h = n;
    for (const d in g) {
      const {
          source: l
        } = g[d],
        m = "entry" in l ? f[l.entry] : p[l.asset],
        P = "entry" in l ? "Entry" : "Asset";
      if (!m) return n;
      const S = r[m.space],
        A = s[m.environment],
        E = m.id,
        w = c[l.field],
        N = u[l.locale],
        O = i[l.editorInterface],
        L = v[l.fieldType];
      if (!(W(O.widgetNamespace) && !U(O.widgetId)) && y.has(h, d)) {
        const M = y.get(h, d);
        if (M !== null) {
          const B = x({
            entityId: E,
            entityType: P,
            space: S,
            environment: A,
            field: w,
            locale: N,
            editorInterface: O,
            fieldType: L,
            targetOrigin: t,
            platform: o
          });
          C(L, M, B, h, d, g);
        }
      }
    }
    return n;
  },
  $ = (e, t, o, n, r) => {
    if (!e.fields) return;
    const {
      contentSourceMaps: s
    } = e.sys;
    if (!s) {
      console.error("Content source maps data is missing");
      return;
    }
    const {
      mappings: i
    } = s;
    for (const c in i) {
      const {
          source: u
        } = i[c],
        f = e.sys.space.sys.id,
        p = e.sys.environment.sys.id,
        g = e.sys.id,
        v = e.sys.type,
        h = t[u.fieldType],
        d = o[u.editorInterface];
      if (W(d.widgetNamespace) && !U(d.widgetId)) continue;
      const l = c.startsWith("/") ? c : `/${c}`;
      if (y.has(e, l)) {
        const m = y.get(e, l);
        if (m === null) return;
        const S = l.split("/").pop();
        if (!S) {
          console.error("Field name could not be extracted from the pointer", l);
          return;
        }
        const A = e.sys.locale;
        if (A) {
          const E = x({
            entityId: g,
            entityType: v,
            space: f,
            environment: p,
            field: S,
            locale: A,
            editorInterface: d,
            fieldType: h,
            targetOrigin: n,
            platform: r
          });
          C(h, m, E, e, l, i);
        } else Object.keys(m).forEach(w => {
          const N = x({
            entityId: g,
            entityType: v,
            space: f,
            environment: p,
            field: S,
            locale: w,
            editorInterface: d,
            fieldType: h,
            targetOrigin: n,
            platform: r
          });
          C(h, m, N, e, `${l}/${w}`, i, w);
        });
      }
    }
  },
  ue = (e, t, o) => {
    var r;
    const n = j(e);
    if (n.sys && "items" in n) {
      const s = n;
      if (!((r = s.sys) != null && r.contentSourceMapsLookup)) return console.error("Content source maps lookup data is missing"), s;
      const {
          contentSourceMapsLookup: {
            fieldTypes: i,
            editorInterfaces: c
          }
        } = s.sys,
        {
          items: u,
          includes: f
        } = s;
      u.forEach(p => $(p, i, c, t, o)), f && f.Entry && f.Entry.forEach(p => $(p, i, c, t, o)), f && f.Asset && f.Asset.forEach(p => $(p, i, c, t, o));
    } else {
      const s = n;
      if (!s.sys.contentSourceMapsLookup) return console.error("Content source maps lookup data is missing"), s;
      $(s, s.sys.contentSourceMapsLookup.fieldTypes, s.sys.contentSourceMapsLookup.editorInterfaces, t, o);
    }
    return n;
  };
export { oe as SUPPORTED_WIDGETS, j as clone, T as combine, x as createSourceMapMetadata, ce as decode, se as encode, ue as encodeCPAResponse, C as encodeField, ae as encodeGraphQLResponse, re as encodeRichTextValue, W as isBuiltinNamespace, U as isSupportedWidget, ie as splitEncoding };
