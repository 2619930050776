import {Injectable} from '@angular/core';
import * as contentful from 'contentful';
import {environment} from '../environments/environment';
import {BehaviorSubject, from, observable, Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { GenericCMSModel, genericEntryIdObj } from '../app/shared/models/cms/generic';
import { GenericLabelsGQL } from '../app/generated/graphql';

@Injectable({
	providedIn: 'root'
})
export class ContentfulService {
	url: string = ""
	total_count!: number;
	apiCount: number = 0;
	apiCounter: number = 0;
	labelsCMSModelData: any = [];
	private client = contentful.createClient({
		space: environment.contentful.spaceId,
		accessToken: environment.contentful.token,
		environment: environment.contentful.environments
	});
	cmsLables: Observable<any> = new Observable();
	currentLang = 'en-US';
	cmsLablesSubject: BehaviorSubject<any>;
	genericlabelsCMSModelData: GenericCMSModel;
	genericlabelsIdObj: genericEntryIdObj;

	constructor(
		private genericLabelsGQL: GenericLabelsGQL
	) {

		this.genericlabelsCMSModelData = new GenericCMSModel();
		this.genericlabelsIdObj = new genericEntryIdObj();
		this.cmsLablesSubject = new BehaviorSubject<any>([]);

		this.genericLabelsGQL.fetch().subscribe(({data}: any) => {
			// let assets = data.assetCollection?.total;
			// let entries = data.entryCollection?.total;
			// this.total_count = Number(assets) + Number(entries);
			this.total_count = data.genericLabelsCollection?.total;
			this.apiCount = Math.ceil(this.total_count / 1000) || 1;
			localStorage.setItem('entriesCount', JSON.stringify(this.total_count));
				this.callEntriesAPI();
		});
	}

	public get getCMSLables(): any {
		return this.cmsLablesSubject.value;
	}

	getIntriesCount() {
		return this.total_count;
	}

	//console logs a response for debugging
	logContent(contentId: any) {
		this.client.getEntry(contentId)
			.then((entry) => console.log())
	}

	//retrieves content mapped to its data fields.
	getContent(contentId: any, userLang: any) {
		const promise = this.client.getEntry(contentId, {
		locale: userLang
		});
    	return from(promise).pipe(map(entry => entry.fields));
  	}

	getAllEntries(userLang: any, skip?: any) {
		const promise = this.client.getEntries({
			locale: userLang,
			include: 0,
			skip: skip,
			limit: 1000
		});
    	return from(promise).pipe(map(entries => entries));
	}
	
	getEntries(userLang: any) {
		const promise = this.client.getEntries({
			locale: userLang,
			limit: 1000
		});
    	return from(promise).pipe(map(entries => entries));
	}

	callEntriesAPI() {
		let contentFulIds = this.genericlabelsIdObj.entryIds;

		for(let count = 0; count<this.apiCount; count++){
			this.getAllEntries(this.currentLang, count * 1000).subscribe((dataobj: any) => {
				this.apiCounter++
				for(let item of dataobj.items) {
					this.labelsCMSModelData.push(item);
				}
				for(let entry of contentFulIds) {
					let Index = dataobj.items.findIndex((x: any) => x.sys.id === entry.id)
					if(Index !== -1 && dataobj.items[Index]['fields']['genericTextLabel'] !== undefined) {
						this.genericlabelsCMSModelData[entry.text] = dataobj.items[Index]['fields']['genericTextLabel'];
					}
				}
				localStorage.setItem('genericLabels', JSON.stringify(this.genericlabelsCMSModelData));
				this.cmsLablesSubject.next(this.labelsCMSModelData);
			});
		}
	}
}