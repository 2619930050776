import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-check-box',
  templateUrl: './custom-check-box.component.html',
  styleUrl: './custom-check-box.component.less',
})
export class CustomCheckBoxComponent {
  @Input() width: number = 26;
  @Input() widthUnit: 'px' | '%' | 'rem' | 'em' = 'px';
  @Input() height: number = 26;
  @Input() heightUnit: 'px' | '%' | 'rem' | 'em' = 'px';
  @Input() rounded: boolean = false;
  @Input() disabled: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() isShadow: boolean = false;
  @Input() isChecked: boolean = false;
  @Output() isCheckedChange = new EventEmitter();

  toggleCheckBox() {
    this.isChecked = !this.isChecked;
    this.isCheckedChange.emit(this.isChecked);
  }
}
