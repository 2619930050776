<div class="custom-textarea-section">
  <label
    for=""
    class="custom-textarea-section__label"
    *ngIf="fieldTitle && showTitle"
    [ngClass]="{
      'custom-textarea-section__label--required': required,
      'custom-textarea-section__label--disabled': disabled
    }"
    >{{ fieldTitle }}</label
  >
  <div
    class="custom-textarea-section__input"
    [style]="{ width: width + widthUnit, height: height + heightUnit }"
    [ngClass]="{
      'custom-textarea-section__input--readonly': readOnly,
      'custom-textarea-section__input--disabled': disabled
    }"
  >
    <textarea
      [ngModel]="textValue"
      (keyup)="validateInput()"
      (ngModelChange)="onUpdate($event)"
      maxlength="{{ charLimitMaxVal === 0 ? '' : charLimitMaxVal }}"
      placeholder="{{ fieldPlaceholder }}"
      (keydown)="disableEnter($event)"
      aria-label="text area"
      [attr.tabindex]="disabled ? '-1' : '0'"
      id="textArea"
      [ngClass]="{ 'custom-textarea-section--readonly': readOnly }"
    ></textarea>
    <span
      class="custom-textarea-section__input--limitcounter"
      *ngIf="limitCounterRequired"
      >{{ textValue.length }}/{{ charLimitMaxVal }}</span
    >
  </div>
  <div
    class="custom-textarea-section__error"
    *ngIf="errorMessage && isErrorMsgRequired"
  >
    {{ errorMessage }}
  </div>
</div>
