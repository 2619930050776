import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../../services/authentication.service';

@Component({
  selector: 'app-side-stepper',
  templateUrl: './side-stepper.component.html',
  styleUrl: './side-stepper.component.less'
})
export class SideStepperComponent {
  profileuUrl : string = 'assets/images/icons/header_person_logo.png';
  @Input() activeStep : string = '';
  pendingStep: string | null = null;
  // activeStep : string = 'offer'
  steps : any = [
    // { key: 'dashboad', value: 'Dashboard', img : 'assets/images/icons/dashboard.png' },
    { key: 'locations', value: 'Location', img : 'assets/images/icons/location_pin.png' },
    { key: 'offers', value: 'Offer', img : 'assets/images/icons/award.png' },
    { key: 'profile', value: 'Profile', img : 'assets/images/icons/profile_tag.png' },
  ]
  activeTooltipKey: string | null = null;

  constructor(
    private router: Router,
    private authenticationService : AuthenticationService
  ) {
    this.profileuUrl = this.authenticationService.currentUser.value.profile_image ? this.authenticationService.currentUser.value.profile_image : 'assets/images/icons/header_person_logo.png';
  }

  navigate(stepKey: string) {
    this.pendingStep = stepKey; // Set the pending step

    // Assuming your `canDeactivate` guard has been properly integrated in the routing
    this.router.navigate([stepKey]).then((navigationSuccess: boolean) => {
      if (navigationSuccess) {
        // Update active step only if navigation was successful
        this.activeStep = this.pendingStep ?? '';
      }
      // Reset pendingStep whether navigation was successful or not
      this.pendingStep = null;
    });
  }

  showTooltip(event: MouseEvent, value: string) {
    this.activeTooltipKey = this.steps.find((step:any) => step.value === value)?.key || null;
  }

  hideTooltip() {
    this.activeTooltipKey = null;
  }
}
