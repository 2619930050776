<div class="google-map-wrapper" [ngStyle]="getAdjustedDimensions()">
  <div class="google-map-wrapper__content">
    <google-map
      [center]="center"
      [zoom]="zoom"
      [options]="mapOptions"
      #map
      [width]="'100%'"
      [height]="'100%'"
    >
      <map-marker
        *ngFor="let marker of markers; let i = index"
        [position]="marker.position"
        [title]="marker.title"
        [icon]="marker?.icon"
      ></map-marker>
    </google-map>
  </div>
</div>
