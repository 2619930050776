import { Component, input, Input } from '@angular/core';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrl: './google-map.component.less',
})
export class GoogleMapComponent {
  @Input() center: google.maps.LatLngLiteral = { lat: 17.385, lng: 78.4867 };
  @Input() zoom = 2;
  @Input() markers: any = [];
  @Input() mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    rotateControl: false,
    keyboardShortcuts: false,
    gestureHandling: 'none',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    draggable: false,
  };
  @Input() height: number = 100;
  @Input() width: number = 100;
  @Input() widthUnit: 'px' | '%' | 'em' | 'rem' = '%';
  @Input() heightUnit: 'px' | '%' | 'em' | 'rem' = '%';
  getAdjustedDimensions() {
    if (this.widthUnit === 'px' && this.heightUnit === 'px') {
      return {
        width: `${this.width - 24}px`,
        height: `${this.height - 24}px`,
      };
    }
    if (this.widthUnit === '%' && this.heightUnit === '%') {
      return {
        width: `calc(${this.width}${this.widthUnit} - 24px)`,
        height: `calc(${this.height}${this.heightUnit} - 24px)`,
      };
    }
    return {
      width: `${this.width - 24}${this.widthUnit}`,
      height: `${this.height - 24}${this.heightUnit}`,
    };
  }
}
