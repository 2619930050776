class DateTimeHelper {
  public static readonly systemTimeFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  public static get dateFormat(): string {
    return JSON.parse((localStorage.getItem('program_date_format') ?? 'DD/MM/YYYY'));
  }
  public static get dateTimeFormat(): string {
    return this.dateFormat + ' hh:mm A';
  }
}

export { DateTimeHelper };
