<app-custom-text-field
  [fieldTitle]="fieldTitle"
  [required]="required"
  [placeholder]="placeholder"
  type="text"
  [value]="display"
  [disabled]="disabled"
  [suffixIcon]="'../../../../../assets/images/icons/calendar.png'"
  iconWidth="20.5px"
  iconHeight="16px"
  (iconClicked)="openPicker()"
  [errorMessage]="errorMessage ?? ''"
  [toolTipMessage]="toolTipMessage ?? ''"
  [tooltipWidth]="tooltipWidth??285"
  [tooltipHeight]="tooltipHeight??65"
  [disableEdit]="true"
  [width]="width"
  [widthUnit]="widthUnit"
  [showError]="showError"
  [includeErrorMsgPaddingFlag]="includeErrorMsgPaddingFlag"
  [highlightOnFocus]="highlightOnFocus"
  [autofocusField]="autofocusField"
  [defaultFocus]="defaultFocus"
  [readOnly]="readOnly"
></app-custom-text-field>
