import { FilterDataType } from "../../interfaces/super-filter-config.interface";

const DUMMY_SUPER_FILTER_DATA = {
    filters: [
      {
        default: ['launched'],
        displayName: 'Offer Status',
        filterKey: 'status',
        type: FilterDataType.MULTI_SELECT,
        options: [
            {
                key: 'launched',
                value: 'Launched',
              },
              {
                key: 'review',
                value: 'In Review',
              },
              {
                key: 'planning',
                value: 'In Planning',
              },
              {
                key: 'terminated',
                value: 'Terminated',
              },
              {
                key: 'expired',
                value: 'Expired',
              },
        ],
        multiSelectConfig: {
            allText: 'All',
            showAll: true,
            selectAll: true,
          },
      },
      {
        default: ['award', 'reward'],
        displayName: 'Offer Type',
        filterKey: 'offer_type',
        type: FilterDataType.MULTI_SELECT,
        options: [
            {
                key: 'award',
                value: 'Award',
              },
              {
                key: 'reward',
                value: 'Reward',
              },
        ],
        multiSelectConfig: {
          allText: 'All',
          showAll: true,
          selectAll: true,
        },
      },
    ],
    sort:  {
        default: 'offer_name',
        displayName: 'Select',
        filterKey: 'sort',
        type: FilterDataType.SELECT,
        options: [
            {
                key: 'offer_name',
                value: 'Title (A to Z)',
              },
              {
                key: '-created_ts',
                value: 'Created Date',
              },
              {
                key: '-end_date',
                value: 'Expiry Date',
              },
        ],
      },
  };
  
  export { DUMMY_SUPER_FILTER_DATA };