<div
  class="toggle-class"
  [ngClass]="{
    'toggle-class__disabled': disabled
  }"
>
  <p
    class="toggle-label"
    [ngClass]="{
      'toggle-label-required': required
    }"
    *ngIf="fieldTitle"
  >
    {{ fieldTitle }}:
  </p>
  <label class="toggle-switch-library">
    <span
      class="angular-slider-library round"
      [attr.tabindex]="disabled ? '-1' : '0'"
      role="status"
      (keyup.enter)="updateToggleValue()"
      (click)="updateToggleValue()"
      [ngClass]="{
        right: toggleValue,
        left: !toggleValue,
        disabled: disabled,
        'read-only': readOnly,
        'deactivate-toggle': deactivate 
      }"
    >
    </span>
  </label>
</div>
