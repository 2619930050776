<div
  class="placeholder"
  [ngStyle]="{ height: height + heightUnit ,
    width: width + widthUnit
   }"
>
  <div
    class="placeholder__logo"
  ></div>
  <div
    class="placeholder__text"
    [ngClass]="{ 'ft-14': subhelpText }"
    [innerHTML]="helpText"
    [ngStyle]="{
      'padding-top': gap + 'px'
    }"
  >
    {{ helpText }} {{width}}
  </div>
  <div class="placeholder__text ft-14">
    {{ subhelpText }}
  </div>
</div>
