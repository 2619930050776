import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-splitted-text-select-field',
  templateUrl: './splitted-text-select-field.component.html',
  styleUrl: './splitted-text-select-field.component.less'
})
export class SplittedTextSelectFieldComponent {
  readonly fieldId: string;

  private _showDropdown: boolean = false;
  get showDropdown(): boolean {
    return this._showDropdown;
  }
  private set showDropdown(value: boolean) {
    this._showDropdown = value;
    if (!this._showDropdown)
      this.errorMessage = this.validateInputValue(
        this.textValue,
        this.selectValue
      );
    this.removeListeners();
    this.clearScrollInterval();
  }

  scrollHandlerRef: any;
  clickHandlerRef: any;
  keyDownHandlerRef: any;
  mouseMovementHandlerRef: any;
  isDefaultDropdown: boolean = false;
  scrollRepeater: any;
  focusedIndex: number = -1;
  searchKey: string = '';
  search$: Subject<string> = new Subject<string>();
  errorMessage?: string;
  @Input() restrictDecimal : boolean = false ;

  // Decorators
  @Input() selectPlaceholder: string = 'Select';
  @Input() inputPlaceholder: string = 'Select';
  @Input() disabled: boolean = false;
  @Input() textValue: any = '';
  @Input() selectValue: any = '';
  @Input() options: { key: any; value: string }[] = [];
  @Input() width: string = '100%';
  @Input() selectFieldWidth: string = '50%';
  @Input() suffixWidth: string = '16px';
  @Input() suffixHeight: string = '16px';
  @Input() suffixPlaceholder?: string;
  @Input() required: boolean = false;
  @Input() fieldTitle?: string;
  @Input() showLoader?: boolean = false;
  @Input() inputType: 'text' | 'number' = 'number';
  @Input() readOnly: boolean = false;
  @Input() inputValidator?: any;
  @Input() NonfieldTitle?: string;
  @Input() fieldTitleshow: boolean = true;
  @Input() allowNegative: boolean = true;
  @Input() hideSelectField?: boolean = false;
  @Input() flipFields: boolean = false;
  @Input() minValue?: number = 0;
  @Input() maxValue?: number;
  @Input() showSeperator: boolean = true;
  @Input() disableSelectDropDown:boolean=false;
  @Output() textValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() showInputPlaceholder: boolean = false;
  // Element References
  @ViewChild('selectMenu') dropdownRef: ElementRef | undefined;
  @ViewChild('inputField') fieldRef: ElementRef | undefined;
  @ViewChild('optionsSection') optionsSectionRef: ElementRef | undefined;
  constructor(private changeDetectionRef: ChangeDetectorRef) {
    this.fieldId = Math.random().toString(36).substring(2, 9);
  }

  ngAfterViewInit() {
    this.positionDropdown();
  }

  // Computed Properties
  get showBottomScrollIndicator(): boolean {
    const optionsElement: HTMLElement = this.optionsSectionRef
      ?.nativeElement as HTMLElement;
    if (optionsElement) {
      const visibleHeigth: number = optionsElement.clientHeight;
      const scrollHeight: number = optionsElement.scrollHeight;
      const topScrollHeight: number = Math.ceil(optionsElement.scrollTop);
      return (
        scrollHeight > visibleHeigth &&
        visibleHeigth + topScrollHeight < scrollHeight
      );
    }
    return false;
  }

  get showTopScrollIndicator(): boolean {
    const optionsElement: HTMLElement = this.optionsSectionRef
      ?.nativeElement as HTMLElement;
    if (optionsElement) {
      const visibleHeigth: number = optionsElement.clientHeight;
      const scrollHeight: number = optionsElement.scrollHeight;
      const topScrollHeight: number = optionsElement.scrollTop;
      return scrollHeight > visibleHeigth && topScrollHeight > 0;
    }
    return false;
  }

  get showIndicator(): boolean {
    const optionsElement: HTMLElement = this.optionsSectionRef
      ?.nativeElement as HTMLElement;
    if (optionsElement) {
      const visibleHeigth: number = optionsElement.clientHeight;
      const scrollHeight: number = optionsElement.scrollHeight;
      return visibleHeigth < scrollHeight;
    }
    return false;
  }

  get displayValue(): string | undefined {
    if (this.options) {
      return this.options
        ?.find((option) => option.key == this.selectValue)
        ?.value.toString();
    }
    return undefined;
  }

  validateInputValue(textInput: any, selectValue: any): string | undefined {
    if (this.required && (textInput?.length <= 0 || selectValue?.length <= 0)) {
      if(this.fieldTitleshow){
        return `${`"${this.fieldTitle}"` ?? 'This field'} is required`;
      }else{
        return `${`"${this.NonfieldTitle}"` ?? 'This field'} is required`;
      }
    }
    if (
      this.inputValidator?.validate &&
      typeof this.inputValidator.validate === 'function'
    ) {
      return this.inputValidator.validate(textInput, selectValue);
    }
    return undefined;
  }

  listenEvents() {
    this.scrollHandlerRef = this.handleScrollEvents.bind(this);
    this.clickHandlerRef = this.handleClickEvents.bind(this);
    this.mouseMovementHandlerRef = this.handleMouseEvent.bind(this);
    this.keyDownHandlerRef = this.keyDownHandler.bind(this);
    window.addEventListener('DOMMouseScroll', this.scrollHandlerRef, {
      passive: false,
    });
    window.addEventListener('wheel', this.scrollHandlerRef, {
      passive: false,
    });
    window.addEventListener('mousewheel', this.scrollHandlerRef, {
      passive: false,
    });
    window.addEventListener('touchmove', this.scrollHandlerRef, {
      passive: false,
    });
    window.addEventListener('click', this.clickHandlerRef, true);
    window.addEventListener('mousemove', this.mouseMovementHandlerRef, true);
    window.addEventListener('mouseenter', this.mouseMovementHandlerRef, true);
    window.addEventListener('keydown', this.keyDownHandlerRef, true);

    this.search$.pipe(debounceTime(500)).subscribe({
      next: (key:any) => {
        const searchKey = key.toLowerCase();
        const matchIndex = this.options.findIndex((option) =>
          option.value.toString().toLowerCase().startsWith(searchKey)
        );
        this.focusOnOption(matchIndex);
        this.searchKey = '';
      },
    });
  }

  focusOnOption(index: number) {
    if (index >= this.options.length) {
      index = this.options.length - 1;
    }
    this.focusedIndex = index;
    if (index > -1) {
      const element = document.getElementById(this.fieldId + '-' + index);
      if (element) {
        element.scrollIntoView();
      }
    }
    this.changeDetectionRef.detectChanges();
  }

  keyDownHandler(event: KeyboardEvent) {
    if (this.showDropdown) {
      event.preventDefault();
      event.stopImmediatePropagation();
    } else {
      return;
    }
    if (event.key == 'ArrowDown' || event.key == 'ArrowUp') {
      let index = event.key == 'ArrowDown' ? 0 : this.options.length - 1;
      const selectedIndex = this.options.findIndex(
        (option) => option.key == this.selectValue
      );
      if (this.focusedIndex > -1) {
        if (event.key == 'ArrowDown') {
          index = this.focusedIndex + 1;
        } else if (this.focusedIndex > 0) {
          index = this.focusedIndex - 1;
        } else {
          index = 0;
        }
      } else if (selectedIndex > -1) {
        index = selectedIndex;
      }
      this.focusOnOption(index);
    } else if (event.code == 'Escape') {
      this.toggleDropdown();
    } else if (event.key == 'Enter') {
      if (this.focusedIndex > -1) {
        const focusedElement = this.options[this.focusedIndex];
        this.selectOption(focusedElement.key);
      } else {
        this.toggleDropdown();
      }
    } else if (
      !(
        event.shiftKey ||
        event.ctrlKey ||
        event.repeat ||
        event.code == 'Backspace' ||
        event.altKey
      )
    ) {
      this.searchKey += event.key;
      this.search$.next(this.searchKey);
    }
  }

  removeListeners() {
    window.removeEventListener('DOMMouseScroll', this.scrollHandlerRef);
    window.removeEventListener('wheel', this.scrollHandlerRef);
    window.removeEventListener('mousewheel', this.scrollHandlerRef);
    window.removeEventListener('touchmove', this.scrollHandlerRef);
    window.removeEventListener('click', this.clickHandlerRef, true);
    window.removeEventListener('keydown', this.keyDownHandlerRef, true);
    window.removeEventListener('mousemove', this.mouseMovementHandlerRef, true);
    window.removeEventListener(
      'mouseenter',
      this.mouseMovementHandlerRef,
      true
    );
  }

  validateKeyboardEvent(e: KeyboardEvent) {
    let { value: inputValue } = e.target as HTMLInputElement;
    if (this.inputType === 'number') {
      if(e.key === '.' && this.restrictDecimal){
        this.textValue = inputValue.replace('.', '');
        e.preventDefault();
        return;
      }
      else if (e.code) {
        if (
          e.code.includes('Digit') ||
          e.code.includes('Numpad') ||
          (!inputValue.includes('.') && e.code.includes('Period')) ||
          (!inputValue.includes('-') &&
            inputValue.length <= 0 &&
            e.code.includes('Minus'))
        ) {
          if (this.validateInputNumberValue(e, inputValue)) {
            return;
          }
          this.textValue = inputValue.replace(/,/g, '');
          return;
        }
        this.textValue = inputValue.replace(/,/g, '');
      }
      e.preventDefault();
    }
  }

  validateInputNumberValue(e: KeyboardEvent, inputValue: string) {
    if (e.code.includes('Digit') || e.code.includes('Numpad')) {
      if (!this.isMinimumValue(inputValue + e.key)) {
        inputValue = this.minValue!.toString();
        this.textValue = inputValue.replace(/,/g, '');
        e.preventDefault();
        return true;
      } else if (!this.isMaximumValue(inputValue + e.key)) {
        inputValue = this.maxValue!.toString();
        this.textValue = inputValue.replace(/,/g, '');
        e.preventDefault();
        return true;
      }
    }
    return false;
  }

  isMinimumValue(value: string): boolean {
    if (this.minValue != null) {
      return parseFloat(value.replace(/,/g, '')) >= this.minValue;
    }
    return true;
  }

  isMaximumValue(value: string): boolean {
    if (this.maxValue != null) {
      return parseFloat(value.replace(/,/g, '')) <= this.maxValue;
    }
    return true;
  }

  formattedTextValue(inputValue: string): string | undefined {
    if (
      this.inputType == 'number' &&
      this.showSeperator &&
      inputValue?.length > 0
    ) {
      return inputValue.replace(',', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return inputValue ?? '';
  }

  selectOption(key: any) {
    this.selectValue = key;
    this.selectValueChange.emit(key);
    this.showDropdown = false;
  }

  emitTextChange(event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    const specialKeys = [
      'Tab', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 
      'Shift', 'Control', 'Alt', 'CapsLock', 'Escape', 
      'Enter', 'PageUp', 'PageDown' , 'Delete'
    ];
  
    if (specialKeys.includes(keyboardEvent.key)) {
      return;
    }
    const { value: inputValue } = event.target as HTMLInputElement;
    if (!this.allowNegative && inputValue.startsWith('-')) {
      // Prevent negative values if allowNegative is false
      (event.target as HTMLInputElement).value = inputValue.replace('-', '');
      this.textValue = inputValue.replace('-', '');
    }
   else if (this.inputType === 'number' && inputValue?.length > 0) {
      this.textValue = inputValue.replace(/,/g, '');
    } else {
      this.textValue = inputValue;
    }
    this.errorMessage = this.validateInputValue(
      this.textValue,
      this.selectValue
    );
    this.textValueChange.emit(this.textValue);
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    this.resetPositions();
    if (this.showDropdown) {
      this.listenEvents();
    }

    this.focusedIndex = -1;
    this.positionDropdown();
    setTimeout(() => {
      this.calculateHeight();
    }, 10);
  }
  trackOptions = (index: number, option: any) => option.key;

  positionDropdown() {
    if (this.dropdownRef && this.fieldRef) {
      const fieldRects = this.fieldRef.nativeElement.getBoundingClientRect();
      if (!this.dropdownRef.nativeElement.style.top.includes('auto')) {
        this.dropdownRef.nativeElement.style.top = fieldRects.top + 'px';
      }
      this.dropdownRef.nativeElement.style.width = 152 + 'px';
      this.dropdownRef.nativeElement.style.left = fieldRects.left + (fieldRects.width-152) + 'px';
      this.dropdownRef.nativeElement.style.right = 'auto';
    }
  }

  handleScrollEvents(event: any) {
    const target = event?.target as HTMLElement;
    this.focusedIndex = -1;
    if (
      this.showDropdown &&
      (!target.className.includes(this.fieldId) ||
        (!this.showBottomScrollIndicator && event.wheelDelta < 0) ||
        (!this.showTopScrollIndicator && event.wheelDelta > 0))
    ) {
      this.preventEvent(event);
    }
  }

  handleClickEvents(event: Event) {
    const target = event?.target as HTMLElement;
    if (this.showDropdown && !target.className.includes(this.fieldId)) {
      this.showDropdown = false;
      this.removeListeners();
      this.clearScrollInterval();
      this.preventEvent(event);
    } else {
      if (target.className.includes('option##')) {
        const clickedOption = this.options.find((option) =>
          target.className.includes(this.keyToClass(option.key))
        );
        if (clickedOption) {
          this.selectOption(clickedOption.key);
        }
      }
      this.preventEvent(event);
    }
    this.changeDetectionRef.detectChanges();
  }
  handleMouseEvent() {
    this.focusedIndex = -1;
  }

  preventEvent(event: Event) {
    this.changeDetectionRef.detectChanges();
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
  }

  scrollTo(direction: 'top' | 'bottom') {
    const element: HTMLElement = this.optionsSectionRef
      ?.nativeElement as HTMLElement;
    this.clearScrollInterval();
    if (direction == 'top' && element) {
      this.scrollRepeater = setInterval(() => {
        if (this.showTopScrollIndicator) {
          element.scrollTop -= 5;
          this.changeDetectionRef.detectChanges();
        } else {
          this.clearScrollInterval();
        }
      }, 15);
    } else if (element) {
      this.scrollRepeater = setInterval(() => {
        if (this.showBottomScrollIndicator) {
          element.scrollTop += 5;
          this.changeDetectionRef.detectChanges();
        } else {
          this.clearScrollInterval();
        }
      }, 15);
    }
  }
  keyToClass(key: string): string {
    return `option##${encodeURI(key)}##option`;
  }


  setDropdownStyles() {
    const fieldComponent: HTMLElement = this.fieldRef
      ?.nativeElement as HTMLElement;
    const dropdownElement: HTMLElement = this.dropdownRef
      ?.nativeElement as HTMLElement;
    const fieldTop = fieldComponent?.getBoundingClientRect()?.top ?? 0;
    const dropdownTop = dropdownElement?.getBoundingClientRect()?.top ?? 0;
    if (dropdownTop < fieldTop) {
      this.isDefaultDropdown = false;
    } else {
      this.isDefaultDropdown =
        !this.showBottomScrollIndicator && !this.showTopScrollIndicator;
    }
  }

  clearScrollInterval() {
    if (this.scrollRepeater) {
      clearInterval(this.scrollRepeater);
    }
  }

  calculateHeight() {
    const fieldElement = this.fieldRef?.nativeElement as HTMLElement;
    const dropdownElement = this.dropdownRef?.nativeElement as HTMLElement;
    if (fieldElement && dropdownElement) {
      const verticalPosition = fieldElement.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;
      const availableHeight = screenHeight - verticalPosition;
      const dropdownHeight = dropdownElement.clientHeight;
      if (availableHeight <= dropdownHeight) {
        this.dropdownRef!.nativeElement.style.top = 'auto';
        this.dropdownRef!.nativeElement.style.bottom = '0px';
        this.dropdownRef!.nativeElement.style.paddingTop = '0px';
        this.dropdownRef!.nativeElement.style.zIndex = '10';
        this.positionDropdown();
      } else {
        this.resetPositions();
        this.positionDropdown();
      }
    }
    this.setDropdownStyles();
  }

  resetPositions() {
    if (this.dropdownRef) {
      this.dropdownRef.nativeElement.style.paddingTop = '42px';
      this.dropdownRef.nativeElement.style.zIndex = '8';
      this.dropdownRef.nativeElement.style.top = '0px';
      this.dropdownRef.nativeElement.style.bottom = 'auto';
    }
  }

  ngOnDestroy(): void {
    this.clearScrollInterval();
    this.removeListeners();
  }
}
