<app-custom-text-field
  [fieldTitle]="fieldTitle"
  [required]="required"
  [suffixIcon]="'../../../../../assets/images/icons/facets.png'"
  iconWidth="16.94px"
  iconHeight="16.94px"
  [disableEdit]="true"
  [value]="getFacetMapping()"
  [width]="width"
  [widthUnit]="widthUnit"
  (iconClicked)="openTransferSelectModal()"
  [placeholder]="placeholder"
  [showError]="showError"
  [disabled]="disabled"
  [errorMessage]="errorMessage"
  [readOnly]="readOnly"
></app-custom-text-field>
