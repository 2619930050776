<div class="datepicker-modal">
  <div class="calendar-header">
    <div class="calendar-title">
      <div>{{ this.title }}</div>
      <img
        src="../../../../../assets/images/icons/calendar@3x.png"
        class="cal-logo"
        alt="calendar"
      />
    </div>
  </div>
  <div class="calendar-body">
    <div class="calendar" #datepicker>
      <ngx-daterangepicker-material
        [showDropdowns]="true"
        [singleDatePicker]="true"
        [autoApply]="true"
        [locale]="localeConfing"
        [startDate]="selectedDate"
        (choosedDate)="updateDate($event)"
        [alwaysShowCalendars]="true"
        [maxDate]="maxDate"
        [minDate]="minDate"
        gravtySelectDateUsingTab
      >
      </ngx-daterangepicker-material>
    </div>
    <div class="time-entry" *ngIf="showTimeFields">
      <div class="time-fields">
        <input
          type="number"
          class="hour"
          max="12"
          min="0"
          placeholder="HH"
          [(ngModel)]="hours"
          (change)="validateTime(hours, 0, false)"
          required
        />
        <div class="seperator">:</div>
        <input
          type="number"
          class="minutes"
          max="59"
          min="0"
          placeholder="MM"
          [(ngModel)]="minutes"
          (change)="validateTime(minutes, 0, true)"
          required
        />
      </div>
      <div class="time-selection">
        <div
          class="am active"
          [ngClass]="{ active: selectedTimeRange == 'AM' }"
          tabindex="0"
          role="button"
          aria-label="AM"
          (keyup.enter)="toggleTimeRange('AM')"
          (click)="toggleTimeRange('AM')"
        >
          AM
        </div>
        <div
          class="pm"
          [ngClass]="{ active: selectedTimeRange == 'PM' }"
          tabindex="0"
          role="timer"
          aria-label="PM"
          (keyup.enter)="toggleTimeRange('PM')"
          (click)="toggleTimeRange('PM')"
        >
          PM
        </div>
      </div>
    </div>
  </div>
  <div class="calendar-footer">
    <app-button
      (clicked)="CancelDate()"
      [height]="40"
      heightUnit="px"
      [width]="155"
      widthUnit="px"
      [fontSize]="15"
      [fontSizeUnit]="'px'"
      [displayText]="'Cancel'"
      [showBorder]="false"
    >
    </app-button>
    <app-button
      [type]="'primary'"
      (clicked)="saveDate()"
      [height]="40"
      heightUnit="px"
      [width]="155"
      widthUnit="px"
      [fontSize]="15"
      [fontSizeUnit]="'px'"
      [displayText]="'Save'"
    >
    </app-button>
  </div>
</div>
