import {
  Component,
  ElementRef,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import dayjs, { Dayjs } from 'dayjs';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DatePickerPayload } from '../../interfaces/date-picker-payload.interface';
import { SharedService } from '../../../../shared/shared.service';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.less',
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent {
  setIntervalListener: any;
  title: string = 'dfghjkl';
  showTimeFields: boolean = false;
  selectedDate: dayjs.Dayjs;
  maxDate!: string;
  minDate!: string;
  outputFormat: string;
  minutes: string = '00';
  hours: number = 12;
  currentSelection: dayjs.Dayjs;
  onSave: Function;
  localeConfing: LocaleConfig = {
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    daysOfWeek: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    firstDay: 1,
  };
  selectedTimeRange: 'AM' | 'PM' = 'AM';
  constructor(
    public dialogRef: MatDialogRef<DatePickerComponent>,
    @Inject(MAT_DIALOG_DATA) payload: DatePickerPayload,
    private sharedService: SharedService
  ) {
    dayjs.tz.setDefault(sharedService.programTimeZone);
    this.showTimeFields = payload.showTimeFields ?? false;
    this.mapMinMaxDate(payload.maxDate, payload.minDate);
    this.selectedDate = this.handleDateTime(payload.selectedDate ?? dayjs());
    this.outputFormat = payload.outputFormat ?? '';
    this.onSave = payload.onSave;
    this.currentSelection = this.selectedDate;
    this.title = payload.title ?? 'Calendar';
    if (payload.selectedDate && payload.showTimeFields) {
      this.minutes = this.selectedDate.format('mm');
      this.hours = parseInt(this.selectedDate.format('hh'));
      this.selectedTimeRange = this.selectedDate.format('A') as 'AM' | 'PM';
    }

    this.setIntervalListener = setInterval(() => {
      this.hidedisablerow();
    }, 10);
  }
  @ViewChild('datepicker') datepicker: ElementRef | undefined;
  ngAfterViewInit(): void {
    if (this.datepicker) {
      this.accessChildElement();
    }
  }
  private accessChildElement(): void {
    const datepickerElement = this.datepicker?.nativeElement;
    const childElement = datepickerElement.querySelectorAll('.available');
    const monthDropdown = datepickerElement.querySelectorAll('.monthselect');
    const yearDropdown = datepickerElement.querySelectorAll('.yearselect');
    monthDropdown.forEach((element: HTMLElement) => {
      element.setAttribute('aria-label', 'Select Month');
    });
    yearDropdown.forEach((element: HTMLElement) => {
      element.setAttribute('aria-label', 'Select Year');
    });
    if (childElement) {
      childElement.forEach((element: HTMLElement) => {
        if (!element.classList.contains('off')) {
          (element as HTMLElement).tabIndex = 0;
          const spanElement = element.querySelector('span');
          if (spanElement) {
            const labelText: any = spanElement.textContent;
            element.setAttribute('role', 'button');
            element.setAttribute('aria-label', labelText);
          }
        }
      });
    }
  }

  validateTime(value: any, index: 0 | 1, isMinutes: boolean) {
    if (isNaN(value)) {
      return;
    }
    if (isMinutes) {
      if (value > 59) {
        value = 59;
      } else if (value <= 0) {
        value = 0;
      }
      this.minutes = value.toString();
    } else {
      if (value > 12) {
        value = 12;
      } else if (value <= 0) {
        value = 1;
      }
      this.hours = value;
    }
  }

  handleDateTime(date: Dayjs) {
    return dayjs(
      date.tz().format('YYYY-MM-DDTHH:mm:ss'),
      'YYYY-MM-DDTHH:mm:ss'
    );
  }

  hidedisablerow() {
    const tableRows = document.querySelectorAll('tbody tr');

    // Loop through each table row
    tableRows.forEach((row) => {
      (row as HTMLTableRowElement).style.display = 'flex';
      // Check if the row contains any table cells with the "off" class
      const hasOffCells = Array.from(row.querySelectorAll('td')).every(
        (cell) =>
          cell.classList.contains('off') && cell.classList.contains('available')
      );

      // If any cell in the row has the "off" class, hide the entire row
      if (hasOffCells) {
        (row as HTMLTableRowElement).style.display = 'none';
      }
    });
  }

  closeCalendar() {
    this.dialogRef.close();
  }
  toggleTimeRange(range: 'AM' | 'PM') {
    this.selectedTimeRange = range;
  }
  updateDate(selection: any) {
    this.currentSelection = selection.startDate;
  }
  saveDate() {
    if (this.showTimeFields) {
      let finalHours = this.hours;
      if (this.selectedTimeRange === 'AM' && this.hours === 12) {
        finalHours = 0;
      } else if (this.selectedTimeRange === 'PM' && this.hours !== 12) {
        finalHours = 12 + this.hours;
      }
      this.currentSelection = this.currentSelection
        .set('minute', parseInt(this.minutes))
        .set('hour', finalHours)
        .set('second', 0);
    }
    const value = this.currentSelection.format('YYYY-MM-DDTHH:mm:ss');
    const outputDate = dayjs.tz(
      value,
      'YYYY-MM-DDTHH:mm:ss',
      this.sharedService.programTimeZone
    );
    const formattedDate = outputDate.tz().format(this.outputFormat);
    if (this.onSave) {
      this.onSave(outputDate, formattedDate);
    }
    this.closeCalendar();
  }
  CancelDate() {
    this.closeCalendar();
  }

  mapMinMaxDate(
    maxDate?: dayjs.Dayjs | string,
    minDate?: dayjs.Dayjs | string
  ) {
    if (maxDate) {
      this.maxDate = maxDate.toString();
      if (this.selectedDate == null) {
        this.hours = Number(dayjs(this.maxDate).tz().format('hh'));
        this.minutes = dayjs(this.maxDate).tz().format('mm').toString();
        this.toggleTimeRange(
          dayjs(this.maxDate).tz().format('A') as 'AM' | 'PM'
        );
      }
    } else {
      this.maxDate = dayjs()
        .set('year', 2500)
        .set('date', 31)
        .set('month', 11)
        .toString();
    }
    if (minDate) {
      this.minDate = minDate.toString();
    } else {
      this.minDate = dayjs()
        .set('year', 1910)
        .set('date', 1)
        .set('month', 0)
        .toString();
    }
  }

  ngOnDestroy(): void {
    if (this.setIntervalListener) {
      clearInterval(this.setIntervalListener);
    }
  }
}
