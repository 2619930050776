<div
  class="pagination-section"
  [style]="{ width: width + widthUnit, height: height + heightUnit }"
>
  <div
    [ngClass]="{ disabled: previousUrl == undefined || previousUrl == 'None' }"
  >
    <button
      class="pagination-section__previous"
      (click)="onClick(previousUrl)"
      [attr.tabindex]="
        previousUrl == undefined || previousUrl == 'None' ? '-1' : '0'
      "
    >
      <img
        class="pagination-section__previous--icon"
        src="../../../../../assets/images/icons/previous@3x.png"
        alt="prev-icon"
      />
      {{getGenericLabels?.Previous}}
    </button>
  </div>
  <div [ngClass]="{ disabled: nextUrl == undefined || nextUrl == 'None' }">
    <button
      class="pagination-section__next"
      [attr.tabindex]="nextUrl == undefined || nextUrl == 'None' ? '-1' : '0'"
      (click)="onClick(nextUrl)"
    >
      {{getGenericLabels?.Next}}
      <img
        class="pagination-section__next--icon"
        src="../../../../assets/images/icons/next@3x.png"
        alt="next-icon"
      />
    </button>
  </div>
</div>
