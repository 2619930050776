<div class="date-range-picker-modal">
  <div class="calendar-header">
    <div class="calendar-title">
      <div>{{ payload.title ?? "Select Date Range" }}</div>
      <img
        src="../../../../../assets/images/icons/calendar@3x.png"
        class="cal-logo"
        alt="calendar"
      />
    </div>
    <div class="calendar-description" *ngIf="payload.description">
      {{ payload.description }}
    </div>
  </div>
  <div class="calendar-body">
    <div
      class="calendar"
      #daterangepicker
      [ngClass]="{
        'end-selected': selectedEndDate,
        'start-date-only':
          selectedStartDate != null && selectedEndDate == undefined
      }"
    >
      <ngx-daterangepicker-material
        [showDropdowns]="true"
        [singleDatePicker]="false"
        [autoApply]="true"
        [locale]="localeConfing"
        [startDate]="startDate"
        [endDate]="endDate"
        [alwaysShowCalendars]="true"
        [maxDate]="maxDate"
        [minDate]="minDate"
        (endDateChanged)="onEndDateSelected($event)"
        (startDateChanged)="onStartDateSelected($event)"
        gravtySelectDateUsingTab
      >
      </ngx-daterangepicker-material>
    </div>
    <div class="time-selection" *ngIf="showTimeFields">
      <div class="time-entry">
        <div class="time-fields">
          <input
            type="number"
            class="hour"
            max="12"
            min="0"
            placeholder="HH"
            [(ngModel)]="hours[0]"
            (change)="validateTime(hours[0], 0, false)"
            required
          />
          <div class="seperator">:</div>
          <input
            type="number"
            class="minutes"
            max="59"
            min="0"
            placeholder="MM"
            [(ngModel)]="minutes[0]"
            (change)="validateTime(minutes[0], 0, true)"
            required
          />
        </div>
        <div class="time-selection">
          <div
            class="am active"
            [ngClass]="{ active: selectedTimeRange[0] == 'AM' }"
            tabindex="0"
            role="button"
            aria-label="AM"
            (keyup.enter)="toggleTimeRange('AM', 0)"
            (click)="toggleTimeRange('AM', 0)"
          >
            AM
          </div>
          <div
            class="pm"
            [ngClass]="{ active: selectedTimeRange[0] == 'PM' }"
            tabindex="0"
            role="button"
            aria-label="PM"
            (keyup.enter)="toggleTimeRange('PM', 0)"
            (click)="toggleTimeRange('PM', 0)"
          >
            PM
          </div>
        </div>
      </div>
      <div class="time-entry" *ngIf="showTimeFields">
        <div class="time-fields">
          <input
            type="number"
            class="hour"
            max="12"
            min="0"
            placeholder="HH"
            [(ngModel)]="hours[1]"
            (change)="validateTime(hours[1], 1, false)"
            required
          />
          <div class="seperator">:</div>
          <input
            type="number"
            class="minutes"
            max="59"
            min="0"
            placeholder="MM"
            [(ngModel)]="minutes[1]"
            (change)="validateTime(minutes[1], 1, true)"
            required
          />
        </div>
        <div class="time-selection">
          <div
            class="am active"
            [ngClass]="{ active: selectedTimeRange[1] == 'AM' }"
            tabindex="0"
            role="button"
            aria-label="AM"
            (keyup.enter)="toggleTimeRange('AM', 1)"
            (click)="toggleTimeRange('AM', 1)"
          >
            AM
          </div>
          <div
            class="pm"
            [ngClass]="{ active: selectedTimeRange[1] == 'PM' }"
            tabindex="0"
            role="button"
            aria-label="PM"
            (keyup.enter)="toggleTimeRange('PM', 1)"
            (click)="toggleTimeRange('PM', 1)"
          >
            PM
          </div>
        </div>
      </div>
    </div>
    <div class="error" *ngIf="errorMessage && errorMessage != ''">
      {{ errorMessage }}
    </div>
  </div>
  <div class="calendar-footer">
    <app-button
    [showBorder]="false"
      (clicked)="closeCalendar()"
      [height]="40"
      heightUnit="px"
      [width]="155"
      widthUnit="px"
      [fontSize]="15"
      [fontSizeUnit]="'px'"
      [displayText]="'Cancel'"
    >
    </app-button>
    <app-button
      [type]="'primary'"
      (clicked)="saveDate()"
      [disabled]="getDisabledCondition()"
      [height]="40"
      heightUnit="px"
      [width]="155"
      widthUnit="px"
      [fontSize]="15"
      [fontSizeUnit]="'px'"
      [displayText]="payload.saveText ?? 'Save'"
    >
    </app-button>
  </div>
</div>
