<div class="transfer-select">
  <div class="transfer-select__header">
    <h3 class="transfer-select__header-title">Manage Facets</h3>
    <app-search-input
      placeholder="Facet Title"
      [(searchValue)]="searchKey"
    ></app-search-input>
  </div>
  <div class="transfer-select__body">
    <div class="transfer-select__column">
      <h4 class="transfer-select__column-title">Available Facets</h4>
      <div class="transfer-select__column-body">
        <button
          class="transfer-select__pill"
          [ngClass]="{ 'facets-pill-first': first, 'facets-pill-last': last }"
          *ngFor="
            let option of availableOptions | filter : { value: searchKey };
            let first = first;
            let last = last
          "
          [title]="option.value"
          (click)="toggleSelect(option.key)"
        >
          <div class="transfer-select__pill-title">{{ option.value }}</div>
          <button class="transfer-select__pill-icon" aria-label="Add"></button>
        </button>
      </div>
    </div>
    <div class="transfer-select__column">
      <h4 class="transfer-select__column-title">Selected Facets</h4>
      <div class="transfer-select__column-body">
        <div
          class="transfer-select__pill transfer-select__pill--selected"
          [ngClass]="{ 'facets-pill-first': first, 'facets-pill-last': last }"
          *ngFor="
            let option of selectedOptions;
            let first = first;
            let last = last
          "
          [title]="option.value"
          (click)="toggleSelect(option.key)"
        >
          <div class="transfer-select__pill-title">{{ option.value }}</div>
          <button
            class="transfer-select__pill-icon"
            aria-label="Remove"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <div class="transfer-select__footer">
    <app-button
      [displayText]="'Cancel'"
      [width]="155"
      [showBorder]="false"
      [height]="40"
      (clicked)="cancelSelection()"
    ></app-button>
    <app-button
      [displayText]="'Save'"
      [type]="'primary'"
      [width]="155"
      [height]="40"
      (clicked)="saveSelection()"
    ></app-button>
  </div>
</div>
