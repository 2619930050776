import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-time-input-field',
  templateUrl: './time-input-field.component.html',
  styleUrl: './time-input-field.component.less',
})
export class TimeInputFieldComponent {
  @Input() fieldTitle?: string = 'Closes';
  @Input() required: boolean = false;
  @Input() showError: boolean = true;
  @Input() errorMessage: string = '';
  @Input() width: number = 141;
  @Input() widthUnit: 'px' | '%' | 'rem' | 'em' = 'px';
  @Input() disable: boolean = false;
  @Input() readOnly: boolean = false;

  @Input() set value(v: string) {
    this.setTimeFromValue(v);
  }
  @Output() valueChange = new EventEmitter<string>();

  hours: string = '';
  mins: string = '';
  secs: string = '';

  constructor() {}

  ngOnInit(): void {}
  setTimeFromValue(value: string) {
    const timeSplit = value?.split(':') ?? [];
    if (timeSplit[0]?.length > 0) {
      this.hours = timeSplit[0];
    }
    if (timeSplit[1]?.length > 0) {
      this.mins = timeSplit[1];
    }
    if (timeSplit[2]?.length > 0) {
      this.secs = timeSplit[2];
    }
    if (value == '' || value == null || value == undefined) {
      this.hours = '';
      this.mins = '';
      this.secs = '';
    }
  }

  setHours(v: string) {
    try {
      if (v == '' || v == null || v == undefined) {
        this.hours = '00';
      } else {
        const val = parseInt(v);
        if (val >= 0 && val <= 23) {
          this.hours = v.length > 2 ? val.toString() : v;
        } else if (v != '') {
          this.hours = '00';
        }
      }
      this.emitValue();
    } catch (error) {
      this.setHours(this.hours);
    }
  }
  setMins(v: string) {
    try {
      if (v == '' || v == null || v == undefined) {
        this.mins = '00';
      } else {
        const val = parseInt(v);
        if (val >= 0 && val <= 59) {
          this.mins = v.length > 2 ? val.toString() : v;
        } else if (v != '') {
          this.mins = '00';
        }
      }
      this.emitValue();
    } catch (error) {
      this.setMins(this.mins);
    }
  }
  padStrings(val: string, force: boolean = false) {
    if (val.length > 0 || force) {
      return val.padStart(2, '0');
    }
    return '';
  }
  emitValue() {
    let value = '';
    if (
      this.hours?.length > 0 ||
      this.mins?.length > 0 ||
      this.secs?.length > 0
    ) {
      value = `${this.padStrings(this.hours, true)}:${this.padStrings(
        this.mins,
        true
      )}:${this.padStrings(this.secs, true)}`;
    }
    this.valueChange.emit(value);
  }
}
